

.card--last {
    @extend .card;

    padding: 8rem 22rem $indent-l;
    background: $color-default;
    border: 1px dashed $color-grey-40;

    &:before,
    &:after {
        display: none;
    }

    @include mi-no-shadow;

    .card__content {
        height: 47rem;
        padding: 0;
        overflow: visible;

        &:before {
            display: none;
        }
    }

    .card__image {
        max-width: 19rem;

        @include mi-image-crisp;
    }

    .card__text {
        margin: 7rem 0 $indent-hor;
        color: $color-grey-40;
    }

    .card__footer {
        display: none;
    }

    @media (max-width: $screen-md) {
        padding: 8rem 10rem $indent-l;
    }

    @media (max-width: $screen-xs) {
        padding: $indent-l $indent-xl;

        .card__text {
            margin: 0;
        }
    }

    @media (max-width: $screen-xxs) {
        padding: $indent-base;
    }
}
