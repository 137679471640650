
.modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: $color-grey-60;
    z-index: 1005;
    overflow-y: auto;

    h4 {
        margin: $indent-xs 0 $indent-s;
        font-family: var(--font-header);
        line-height: $line-height-xl;
    }

    p {
        &.is-muted {
            margin: 0;
            font-size: $font-size-s;
            font-weight: $font-weight-semibold;
            color: $color-text-light;
        }
    }

    .modal__title_img
    {
        max-width: 70%;
        max-height: 6.4rem;
        margin: 0 0 $indent-m;
    }

    .modal__container {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        margin: 15rem auto;
        overflow: initial;
        width: 56rem;
        max-width: 90%;
        height: auto;
        padding: $indent-xl $indent-xxl;
        background: $color-bg;
        border-radius: $border-radius;
        text-align: center;

        &.no__padding {
            padding: 0;
        }

        h2 {
            margin: 0 0 $indent-hor;
            color: var(--color-theme);
            line-height: 1.33;
        }

        p {
            &:last-of-type {
                margin-bottom: 0;

                + .actions {
                    margin-top: $line-height-m;
                }
            }
        }

        img
        {
            + h2
            {
                margin-top: $indent-m;
            }
        }

        &.modal__container___large {
            width: 75rem;
            padding: $indent-xl $indent-xxl;
        }

        &.modal__container__x-large {
            width: 88rem;
            padding: $indent-xl $indent-xxl;

            .form__fieldset {
                margin-bottom: 0;
            }

            .actions {
                margin-top: $indent-xl;
            }
        }
    }

    .modal__step {
        margin: 0 0 $indent-m;
        color: $color-text;
        font-size: $font-size-default;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
    }

    .modal__close {
        position: absolute;
        top: $indent-m;
        right: $indent-l;
        z-index: 1000;
        color: $color-grey-60;
        font-size: 3.5rem;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            color: $color-grey-80;
            text-decoration: none;
        }
    }

    .modal__list_item {
        margin: 0 0 $indent-l;
        padding: 0 0 $indent-l;
        border-bottom: 1px solid $color-border-light;

        .starter {
            color: $color-alert;
        }

        .gevorderd {
            color: $color-text;
        }

        .beroepsbekwaam {
            color: $color-success;
        }
    }

    .modal__emph_block {
        margin: $indent-s 0 0;
        padding: $indent-m;
        background: $color-grey-10;
    }

    .is-loading {
        &.is-loading--center {
            margin: 0 auto;
        }
    }

    .filelister {
        margin: 0 (-$indent-xxl);
    }

    .checkbox_list {
        margin-bottom: $indent-xl;

        > li {
            &.expanded {
                background: $color-grey-5;
            }
        }
    }

    .table {
        margin: 0 0 $indent-l;
    }

    table {
        .actions__action--download {
            margin-left: $indent-s;
        }

        .actions__action--download,
        .actions__action--delete {
            font-size: $font-size-s;
        }
    }

    .modal__screenshot
    {
        width: 100%;
        margin: $indent-l 0 0;

        @include mi-flexbox();
        @include mi-align-items(flex-end);
        @include mi-flex-justify-content(space-evenly);

        > a
        {
            display: inline-block;

            i
            {
                float: left;
                margin-right: $indent-xs;
            }
        }

        .modal__screenshot__link
        {
            margin-bottom: $indent-xs;
            text-decoration: underline;

            &:hover,
            &:active
            {
                text-decoration: none;
            }
        }

        .modal__screenshot__image
        {
            margin-left: $indent-m;
            text-decoration: none;

            &:hover,
            &:active,
            &:focus
            {
                text-decoration: none;
            }

            img
            {
                width: 15rem;
            }
        }
    }

    @media (max-width: $screen-md) {
        .modal__container {
            width: 51rem;

            &.modal__container__x-large {
                padding: $indent-xl $indent-l;
            }
        }
    }

    @media (max-width: $screen-xs) {
        .modal__title_img
        {
            height: 3.6rem;
            margin: 0;
        }

        .modal__container {
            top: $indent-base;
            padding: $indent-l $indent-xs $indent-l;
            margin-top: $indent-base;
            margin-bottom: $indent-xl;

            &.modal__container__x-large {
                padding: $indent-xl $indent-xs;
            }

            h2 {
                margin-bottom: $indent-s;
                line-height: 1.4;
            }
        }

        .filelister {
            margin: 0 (-$indent-s);
            overflow-x: auto;

            table {
                min-width: 40rem;
            }
        }

        .modal__close {
            top: 0;
            right: $indent-s;
        }
    }

    @media (max-width: $screen-xxs) {
        .form {
            .form__item {
                padding: $indent-l 0 0;

                .form__icon {
                    display: none;
                }
            }

            .form__label {
                width: 100%;
                left: 0;
                line-height: 1;

                span {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            .form__item--checkbox,
            .form__item--radio {
                padding-left: 0;
            }
        }
    }
}
