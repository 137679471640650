

.list_item--full_width {
    @extend .list_item;

    display: block;
    padding: $indent-l 0 $indent-s;

    &:first-of-type {
        padding-top: 0;
    }

    h3 {
        text-transform: uppercase;
    }

    .actions {
        position: relative;
        text-align: left;
        bottom: 0;

        .actions__action--edit,
        .actions__action--delete {
            float: none;
        }

        .actions__action--save {
            color: var(--color-cta-text);
            margin: 0;
        }
    }

    @media (max-width: $screen-xs) {
        .actions {
            .actions__action {
                + .actions__action,
                + .actions__action--single {
                    float: right;
                    margin: 0;
                }
            }

            .actions__action--save,
            .actions__action--between {
                display: block;
                float: none !important;
                margin: $indent-s 0;
                text-align: center;
            }
        }
    }
}
