.panel--multi {
    @extend .panel;

    overflow: hidden;

    .form__header {
        p {
            line-height: $line-height-default;
        }
    }

    .button_container {
        position: relative;
    }

    .button--cta {
        margin-top: 0;

        + .button--cta {
            margin-top: $indent-s;
        }
    }

    .panel__header {
        padding-top: 0;
        padding-bottom: $indent-m;
    }

    .panel__body
    {
        padding-top: $indent-m;
        padding-bottom: $indent-xs;

        .panel--multi__inner
        {
            padding-left: 0;

            .panel__body {
                padding: 0;
                border: none;
            }

            .panel__subheader {
                .button--cta {
                    z-index: 1;
                }
            }

            .panel__header
            {
                padding: 0 0 $indent-m;
                background: none;

                h2
                {
                    color: $color-text-lighter;
                }
            }

            &.panel--multi__inner__uneditable .panel__header h2
            {
                margin-bottom: $indent-m;
            }

            .form--panel
            {
                margin: $indent-m 0 $indent-l;
            }

            .panel__tags {
                margin-top: $indent-s;
                padding-bottom: $indent-m;
            }
        }

        .panel--suggestion {
            .panel__body {
                padding-bottom: $indent-l;
                background: transparent;
                border: none;
                text-align: left;

                .suggestion {
                    margin: $indent-s 0;
                    padding: 0;
                    font-weight: $font-weight-regular;
                }
            }
        }

        .panel--expand
        {
            margin: 0;
            box-shadow: none;

            &.tags,
            &.tags--mobile
            {
                .panel__body
                {
                    max-height: 20rem;

                    &.expanded
                    {
                        max-height: none;
                    }
                }
            }

            .panel__subheader
            {
                margin-top: -$indent-s;
                padding-bottom: $indent-m;
            }

            .panel__body
            {
                max-height: 35rem;

                &.expanded
                {
                    max-height: none;
                }

                &:after
                {
                    bottom: 0rem;
                }
            }

            .button__panel
            {
                left: 0;
                right: 0;
            }
        }

        .panel__btn-expand
        {
            padding: $indent-l 0;
        }

        .panel--suggestion
        {
            margin: 0;

            .panel__header {
                padding: 0 0 $indent-m;

                @include mi-no-shadow();
            }

            .panel__body
            {
                padding: 0 0 $indent-l;

                .suggestion__icon
                {
                    display: none;
                }
            }
        }

        .panel__table
        {
            margin: 0 0 $indent-l;
        }

        .panel__sub_block
        {
            > .actions
            {
                padding: $indent-xs;
                background: $color-grey-10;

                .actions__action--delete,
                .actions__action--graph
                {
                    margin: $indent-action 0 0 $indent-m;
                }
            }

            .panel__btn-expand
            {
                padding: $indent-l 0 $indent-xl;
            }


            &:last-of-type
            {
                .panel__btn-expand
                {
                    padding-bottom: $indent-l;
                }
            }

            .legend
            {
                margin: $indent-l 0 $indent-xs;
                text-align: right;

                li {
                    margin: 0 0 0 $indent-m;
                }
            }
        }
    }

    .list_item {
        &:last-child {
            padding-bottom: $indent-s;
        }
    }

    .spacer-hr {
        margin-top: 0;
    }

    @media (max-width: $screen-md) {
        .button__panel {
            margin-top: $indent-s;
        }
    }

    @media (max-width: $screen-sm) {
        .panel__header {
            .button--cta {
                right: -$indent-hor;
                top: -$indent-xs;

                &.second {
                    top: $indent-l + $indent-xxs;
                }
            }
        }

        .panel__subheader {
            .button--cta {
                right: -$indent-hor;
            }
        }

        .panel__body {
            .panel--expand {
                &.tags,
                &.tags--mobile {
                    .panel__body {
                        max-height: 27rem;

                        &.expanded {
                            max-height: none;
                        }
                    }
                }
            }

            .panel--multi__inner {
                .form--panel {
                    margin:  0;
                    padding: 0;
                }

                .tag {
                    margin: 0 $indent-xs $indent-xs 0;
                }

                .panel__sub_block {
                    &:last-of-type
                    {
                        .panel__btn-expand
                        {
                            padding-bottom: $indent-m;
                        }
                    }

                }
            }
        }
    }

    @media (max-width: $screen-xs) {
        .panel__header {
            padding: 0;

            .button--cta {
                right: -$indent-s;
                top: -$indent-xs;
            }
        }

        .panel__subheader {
            .button--cta {
                right: -$indent-s;
            }
        }

        .panel__body {
            .panel--multi__inner {
                .button--cta {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .panel__table {
                margin-bottom: $indent-xs;
            }

            .panel__table--bordered {
                margin: 0 0 $indent-s;

                tbody {
                    tr,
                    tr:nth-child(1){
                        td, th {
                            padding: 0 $indent-xs 0 0;
                        }
                    }
                }
            }

            .panel--suggestion {
                .panel__body {
                    padding: 0 0 $indent-m;

                    .suggestion {
                        margin: 0 0 $indent-xs;
                    }
                }
            }

            .panel__sub_block
            {
                .actions
                {
                    padding: $indent-xs;

                    .actions__action--delete,
                    .actions__action--graph
                    {
                        width: 2.4rem;
                        height: 2.4rem;
                        border: none;
                        background: transparent;

                        @include mi-no-shadow();

                    }
                }
            }
        }
    }
}
