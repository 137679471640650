

.button--icon {
    position: relative;
    display: inline-block;
    font-size: 4rem;
    color: $color-text;
    border-radius: 50%;

    @extend .icomoon;

    @include mi-transition();

    &.icomoon-check {
        color: $color-success;

        &:hover,
        &:active {
            @include mi-drop-shadow-color(0, 0, 6px, 0, var(--color-cta-text));
        }
    }

    &.icomoon-cross {
        color: $color-alert;

        &:hover,
        &:active {
            @include mi-drop-shadow-color(0, 0, 6px, 0, $color-alert);
        }
    }

    &:hover,
    &:active {
        cursor: pointer;

        @include mi-drop-shadow-color(0, 0, 6px, 0, $color-grey-40);
    }

    &:focus {
        outline: none;

        @include mi-shadow-color(0, 0, 0, 1px, $color-grey);
    }

    &:before {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0.2rem;
        top: 0.2rem;
        display: block;
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 50%;
        background: $color-default;
        z-index: 0;
    }
}
