

.modal--delete {
    @extend .modal;

    overflow: auto;

    .modal__container {
        .modal__header {
            margin: (-$indent-xl) (-$indent-xxl) $indent-xl (-$indent-xxl);
            padding: $indent-xl 0 $indent-base 0;
            background: $color-alert;

            h2,
            p,
            i {
                color: $color-default !important;
            }

            h2 {
                margin-bottom: $indent-s;
            }

            .icons {
                margin-right: $indent-base;
                margin-left: -4.5rem;
            }
        }

        .modal__list {
            text-align: left;

            ul {
                margin: $indent-base 0 $indent-xl 0;
                padding-left: $indent-l;
                color: $color-grey-60;
            }

            .text--lighter {
                margin: 0;
                color: $color-grey-60;
            }

            .form__item {
                padding: 2rem 0 0 0;
            }
        }

        .actions {
            position: relative;
            float: none;
            color: $color-grey-80;
            right: auto;
            bottom: auto;
            text-align: left;

            .actions__action--cancel {
                display: inline-block;
                margin: 0;
                font-size: $font-size-default;
                line-height: $line-height-default;
            }

            .button--alert {
                float: right;
                margin: -0.8rem 0 0;
            }
        }
    }

    @media (max-width: $screen-xs) {
        .modal__container {
            padding: $indent-m;

            .modal__header {
                margin: (-$indent-m) (-$indent-m) $indent-xl (-$indent-m);
                padding: $indent-xl 0 $indent-base 0;
                background: $color-alert;
            }

            .form__fieldset {
                margin: 0 0 $indent-m;
            }
        }
    }
}
