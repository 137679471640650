

.card--bg_img {
    @extend .card;

    .card__content {
        background: var(--backdrop-desktop) no-repeat top left;
        background-size: cover;
    }

    .card__text {
        color: $color-default;
    }

    .card__subject {
        color: $color-default;
    }

    .card__subject--small {
        @extend .card__subject;

        font-size: 5rem;
        line-height: 5rem;
    }

    @media (max-width: $screen-md) {
        .card__subject--small {
            font-size: 4rem;
        }
    }

    @media (max-width: $screen-xs) {
        height: $card-height-mobile;
        margin: 0 0 $indent-xl;

        .card__subject--small {
            font-size: 3rem;
        }
    }

    @media (max-width: $screen-xxs) {
        .card__subject--small {
            font-size: 2rem;
            word-wrap: break-word;
        }
    }
}
