
.panel {
    width: 100%;
    height: auto;
    margin: $indent-l 0 0;
    padding: 0;
    border-radius: var(--border-radius);
    color: $color-grey-80;
    background-color: $color-bg;

    @include mi-drop-shadow(0, 1px, 3px, 0, 0.3);

    h2 {
        margin: 0.3rem 0;
        line-height: $line-height-s;
        font-size: $font-size-h2;
    }

    p {
        a {
            text-decoration: underline;

            &:hover,
            &:active {
                text-decoration: none;
            }
        }
    }

    .text--large {
        font-size: $font-size-default;
        line-height: $line-height-default;
        font-weight: $font-weight-regular;
    }

    .button--cta {
        margin-bottom: 0;
    }

    .button__panel {
        + .panel__tags,
        + .panel__form {
            margin-top: $indent-l;
        }
    }

    .panel__header {
        padding: $indent-l $indent-hor;
        background: $color-default;
        color: $color-text-lighter;
        border-radius: var(--border-radius) var(--border-radius) 0 0;

        table {
            width: 100%;
            border-collapse: collapse;
        }

        td {
            padding: 0;
            vertical-align: middle;

            &.button_container {
                padding-left: $indent-l;
            }
        }

        h2, h3 {
            position: relative;
            margin: 0;
            padding-top: $indent-xxs;
            color: $color-text;
            line-height: 2.5rem;
        }
        

        .button--cta {
            float: right;
            width: auto;
            margin-top: 0;

            &.panel__button--icon_only {
                float: none;
                position: absolute;
                right: 0;
                top: $indent-l - $indent-xxs;
                border-radius: $border-radius 0 0 $border-radius;

                &.is-loading {
                    i {
                        display: inline-block;
                        color: transparent;
                    }
                }
            }
        }

        .panel__header__icon {
            position: relative;
            top: -2px;
            display: inline-block;
            margin-right: $indent-xs;
            font-size: $font-size-icon;
            vertical-align: top;
        }
    }

    .panel__subheader {
        @include mi-flexbox();
        @include mi-flex-direction(row);
        @include mi-flex-justify-content(flex-end);

        .button--cta {
            width: auto;
        }
    }

    &.no-icon {
        h2 {
            padding-left: 0;
        }
    }

    .panel__profile_img {
        .panel__profile_img__img {
            position: relative;
            display: inline-block;
            width: 13.5rem;
            height: 13.5rem;
            overflow: hidden;
            vertical-align: middle;
            border-radius: 50%;
            border: 0.7rem solid transparentize($color-default, 0.8);
        }

        .panel__profile_img__bg {
            width: 100%;
            height: 100%;
            background: $color-default;
        }

        img {
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-height: 100%;
            max-width: 100%;
            min-width: 100%;
            @include mi-image-crisp();
        }
    }

    .panel__intro {
        display: inline-block;
        width: 100%;
        padding-right: 0;
        vertical-align: middle;
        color: $color-default;

        .panel__intro__text {
            padding: $indent-m 0;

            @include mi-flexbox();
            @include mi-flex-direction(column);
            @include mi-flex-justify-content(center);
        }

        h2 {
            font-size: 3.2rem;
            font-weight: bold;
            line-height: 3.2rem;
        }

        p {
            margin: 0;
            font-size: $font-size-default;

             span {
                 display: block;
                 font-style: italic;
             }
        }

        .actions {
            position: absolute;
            top: $indent-action;
            right: -$indent-action;
        }
    }

    .panel__body {
        position: static;
        width: 100%;
        padding: 1px $indent-hor $indent-l;
        background: $color-bg;
        border-radius: 0 0 var(--border-radius) var(--border-radius);

        &.panel__light {
            background: $color-grey-5;
        }

        h2 {
            letter-spacing: 1px;
        }

        .button--add {
            position: absolute;
            top: $indent-s;
            right: 0;
        }
    }

    .panel__body--small {
        @extend .panel__body;

        padding-top: 0;
    }

    .panel__list {
        display: block;
        margin: 0 0 $indent-l;
        padding: 0;

        p {
            line-height: $line-height-xl;
            margin: 0;
            font-size: $font-size-s;
            font-weight: $font-weight-medium;
        }

        .list_item {
            p {
                line-height: $line-height-s;
                margin: $indent-xxs 0;
                font-size: $font-size-s;
                font-weight: $font-weight-medium;
            }
        }

        &:first-child {
            margin: 0;
        }
    }

    .panel__edit {
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        font-style: italic;
        text-decoration: none;

        @include mi-transition();

        &:hover,
        &:active {
            cursor: pointer;
            text-decoration: underline;
            color: inherit;
        }
    }

    .panel__dot {
        font-size: 2rem;
        width: 15rem;

        &.good {
            color: $color-cta;
        }

        &.medium {
            color: $color-alt;
        }

        &.bad {
            color: $color-alert;
        }
    }

    .panel__quote {
        text-align: center;
    }

    .panel__table {
        width: 100%;
        border-collapse: collapse;

        tr {
            .center {
                .actions {
                    text-align: center;
                }
            }
        }

        .actions {
            text-align: right;

            .actions__action--edit,
            .actions__action--delete,
            .actions__action--upload,
            .actions__action--view,
            .actions__action--graph,
            .actions__action--share,
            .actions__action--download{
                font-size: $font-size-s;

                &:hover,
                &:active {
                    text-decoration: none;
                }

                &.is-loading {
                    margin-left: $indent-hor;
                }
            }

            .actions__action--delete,
            .actions__action--upload {
                margin-left: $indent-s;
            }
        }

        thead {
            tr {
                th {
                    width: 0;
                    padding: $indent-s $indent-s $indent-s 0;
                    font-size: $font-size-s;
                    line-height: $line-height-s;
                    text-align: center;
                }
            }
        }

        tr {
            td {
                padding: $indent-s $indent-s $indent-s 0;
                line-height: $line-height-m;
            }

            th,
            td {
                text-align: center;

                &:first-child,
                &.first-child {
                    text-align: left;
                }

                &:last-child,
                &.last-child {
                    text-align: right;

                    &:first-child{
                        text-align: left;
                    }
                }

                &.last-child {
                    width: $indent-s;
                }

                    &:first-child {
                    padding-left: $indent-s;
                }
            }

            th {
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    width: 1.5rem;
                }
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background: $color-grey-10;
                }
            }
        }
    }

    .panel__buttons {
        margin: 0 0 $indent-l;
    }

    .panel__table--bordered {
        @extend .panel__table;

        border-bottom: 1px solid $color-border-light;
        overflow: hidden;

        tbody {
            tr {
                &:first-child {
                    td {
                        padding-top: $indent-xs;
                        padding-left: 0;
                        font-weight: $font-weight-medium !important;
                    }
                }
                &:nth-child(odd) {
                    background: transparent;
                }
                &:nth-child(2) {
                    border-top: none;
                }

                border-top: 1px solid $color-border-light;

                td {
                    padding-top: 0;
                    padding-bottom: $indent-xs;
                    padding-left: 0;
                    line-height: 1;

                    &.first-child {
                        padding-top: 0.6rem;
                        padding-bottom: 0.6rem;
                        font-weight: $font-weight-regular;
                        line-height: 2.4rem;
                    }
                }

                td {
                    &.panel__dot {
                        text-align: center;
                    }

                    &.panel__table__icon {
                        a {
                            &:hover,
                            &:active {
                                text-decoration: none;
                                border-bottom: none;

                                color: var(--color-theme-light);
                            }
                        }

                        .icons {
                            font-size: $font-size-icon-s;
                        }
                    }

                    &.no_border {
                        min-width: $indent-s;
                        padding: 0;
                        border-top: 1px solid $color-default;
                    }
                }
            }
        }

        thead {
            tr {
                th {
                    border-bottom: 1px solid $color-border;
                    width: 8rem;
                    vertical-align: bottom;
                    white-space: nowrap;
                }
            }
        }
    }

    .panel__table--clickable_rows {
        tr {
            td {
                @include mi-transition();
            }

            &:hover,
            &:active {
                cursor: pointer;

                td {
                    background: $color-grey-5;
                    border-top: 1px solid $color-border-light;
                }
            }
        }
    }

    .panel__table--languages {
        @extend .panel__table;

        tr th:last-child {
            text-align: center;
        }
    }

    .panel__tags {
        padding: 0;
        overflow: hidden;
    }

    .panel__actions {
        .actions {
            margin: $indent-m 0;
            padding: $indent-s $indent-s;
            background: $color-grey-10;
        }
    }

    .list_item--full_width {
        p {
            margin: $line-height-m 0 0;
            font-size: $font-size-default;
            line-height: $line-height-default;
            font-weight: $font-weight-regular;
        }
        h3 {
            color: $color-grey-60;
        }
    }

    @media (max-width: $screen-md) {
        .panel__intro {
            padding-right: 0;
        }

        .button__panel {
            margin: 0 0 $indent-s;
        }

        .panel__table--languages {
            tr td:last-child, tr td:first-child, tr th:first-child {
                text-align: center;
            }
        }

        .panel__dot {
            width: 9rem;
        }
    }

    @media (max-width: $screen-sm) {
        .panel__header {
            h2 {
                font-size: $font-size-l;
            }

            td {
                &.button_container {
                    padding-left: $indent-xxl;
                }
            }

            .button--cta {
                float: none;
                position: absolute;
                margin: 0;
                padding-left: $indent-m;
                padding-right: $indent-m;
                border-radius: $border-radius 0 0 $border-radius;
                width: $indent-xxl !important;

                &.panel__button--icon_only {
                    top: $indent-m;
                }

                &.is-loading {
                    padding-left: $indent-m;

                    i {
                        display: inline-block;
                        color: transparent;
                    }
                }
            }
        }

        .panel__subheader {
            display: block;
            .button--cta {
                float: right;
                margin: 0;
                padding-left: $indent-m;
                padding-right: $indent-m;
                border-radius: $border-radius 0 0 $border-radius;
                width: $indent-xxl !important;
            }
        }

        .panel__body {
            padding-bottom: $indent-m;

            .button--cta {
                margin-bottom: $indent-xs;
            }
        }

        .panel__tables {
            h3 {
                font-size: $font-size-l;
            }

            .actions {
                margin-bottom: $indent-xs;
                padding: 0;

                .actions__action--delete {
                    float: right;
                }
            }

            &--no_margin {
                .panel__table {
                    margin-bottom: 0 !important;
                }

                .actions {
                    margin-bottom: 0 !important;
                }
            }
        }

        .panel__table--bordered {
            thead {
                tr {
                    th {
                        width: auto;

                        &:first-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $screen-xs) {
        margin: $indent-base 0 0;

        h3 {
            margin: $indent-m 0 0;
        }

        .panel__body {
            padding: $indent-m $indent-base $indent-base;
        }

        .panel__table {
            margin: 0 0 $indent-s;
        }

        .panel__header {
            padding: $indent-base;

            h2
            {
                font-size: $font-size-default;
            }

            .panel__header__icon {
                margin-right: $indent-base;
            }

            .button--cta {
                right: 0;
                top: $indent-xs;

                &.panel__button--icon_only {
                    top: $indent-xs;
                }
            }
        }
    }

    @media (max-width: $screen-xxs) {
        .button--cta {
            margin-left: 0;
            margin-right: 0;
        }

        .panel__table {
            .actions {
                .actions__action--delete,
                .actions__action--upload {
                    margin-left: $indent-action;
                }
            }
        }
    }

    h4.vacancy-title {
        margin-bottom: 1.2rem;
    }

    .line-wrapper {
        padding-top: 0.5rem;
        padding-bottom: 0.5;
        @media (max-width: $screen-xs) {
            padding-bottom: 0rem;
        }
    }

    .line-breaker {
        display: flex;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
}
