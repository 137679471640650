
.score_board
{
    @include mi-flexbox;

    text-align: center;

    .score_board__col
    {
        @include mi-flex(1 1 33.33%);
    }

    .score_board__item
    {
        position: relative;
        background: $color-default;

        label
        {
            position: relative;
            display: block;
            padding: $indent-m;
            color: $color-text;
            text-align: center;
            outline: 1px solid $color-border-light;

            @include mi-transition();

            i
            {
                font-size: $font-size-icon;
            }

            > .score_board__label_text
            {
                display: block;
                margin:  0;
                font-size: $font-size-default;
                line-height: $line-height-s;
            }

            &:hover,
            &:active
            {
                cursor: pointer;

                i
                {
                    color: var(--color-theme);
                }
            }
        }

        input[type="radio"]
        {
            position: absolute;
            left: 0;
            top: 0;

            @include mi-opacity(0);

            &:checked
            {
                + label
                {
                    background: var(--color-theme);
                    color: $color-default;
                    outline-color: var(--color-theme);
                    z-index: 1;

                    &:hover,
                    &:active
                    {
                        cursor: default;

                        i
                        {
                            color: $color-default;
                        }
                    }
                }
            }
        }
    }

    .score_board__info_btn
    {
        display: inline-block;
        margin: $indent-s 0 0;

        @include mi-transition();

        &:hover,
        &:active
        {
            cursor: pointer;
            color: var(--color-theme-light);
        }
    }
}
