
.list_profile {
    display: flex;
    .list_profile__image_container {
        width: 8rem;
        height: 8rem;
        margin-right: $indent-l;
        border-radius: 50%;
        background: var(--color-theme);
        vertical-align: middle;
        border: 0.7rem solid transparentize($color-default, 0.5);
    }
    .list_profile__image {
        width: 6.6rem;
        height: 6.6rem;
        border-radius: 50%;
        background: $color-default;
        vertical-align: top;
    }
    .list_profile__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .list_profile__info_inside {
        }
        .list_profile__info__name {
            font-size: 125%;
            font-weight: bold;
            word-break: break-word;
        }
        .list_profile__info__email {
            word-break: break-word;
        }
    }
    + .list_profile {
        border-top: 1px solid $color-grey-20;
        margin-top: $indent-l;
        padding-top: $indent-l;
    }
}
