
.file_input {
    position: relative;
    width: 100%;
    margin: $indent-xs 0 0;
    padding: 0 0 0 $indent-l;
    font-size: $font-size-default;

    .file_input__icon {
        position: absolute;
        left: 0;
        top: $indent-xs;
        font-size: $font-size-default;
    }

    input {
        width: 100%;
        padding: 0.5rem 0;
        border-top: 1px solid transparent;
        border-bottom: 1px solid $color-border;
        font-family: var(--font-default);;
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        font-style: italic;
        color: $color-text;

        @include mi-transition();

        &:focus {
            border-top: 1px solid $color-border;
        }
    }
}

.file_input__label {
    display: block;
    line-height: $line-height-default;
    margin: $indent-m 0;
    padding: 1.3rem $indent-m;
}
