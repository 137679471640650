
.popper-active,
.popper-active .panel {
    position: relative;
    z-index: 1200;
    pointer-events: none;
    border-radius: $border-radius;
    .panel {
        overflow: hidden;
    }
}

.intro-card {
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    width: 300px;
    padding: 30px;
    position: absolute;
    left: 100px;
    z-index: 1249;
    text-align: center;
    top: 30px !important;
    margin-top: -30px;
    margin-bottom: 30px;

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: -20px;
        margin-left: -10px;
        z-index: 1250;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #fff;
    }

    &[data-popper-placement="top"] {
        inset: auto !important;
        top: auto !important;
        bottom: 30px !important;

        &::before {
            top: unset;
            bottom: -20px;
            border-bottom: 0;
            border-top: 20px solid #fff;
        }
    }

    &[data-popper-placement="right"] {
        margin-top: 0;
        margin-left: 35px;
        &::before {
            border-left: 0;
            margin-left: -20px;
            left: 0;
            right: auto;
            bottom: auto;
            top: 50%;
            border-bottom: 20px solid transparent;
            border-top: 20px solid transparent;
            border-right: 20px solid #fff;
        }
    }
    &[data-popper-placement="left"] {
        margin-top: 0;
        margin-right: 53px;
        &::before {
            border-right: 0;
            margin-right: -20px;
            left: auto;
            bottom: auto;
            right: 0;
            top: 50%;
            border-bottom: 20px solid transparent;
            border-top: 20px solid transparent;
            border-left: 20px solid #fff;
        }
    }
}

.intro-card h2 {
    color: var(--color-theme);
    text-transform: none;
}

.intro-card-bg {
    background-color: rgb(0, 23, 48);
    pointer-events: none;
    opacity: 0.5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1100;
}
