
.roadmap-item {
    margin-top: $indent-xl;

    &:nth-child(even) {
        .roadmap-item__content {
            &.right {
                display: none;
            }
        }
    }

    &:nth-child(odd) {
        .roadmap-item__content {
            &.left {
                display: none;
            }
        }
    }

    .roadmap-item__content {
        display: inline-block;
        width: 100%;
        padding: $indent-base;
        color: $color-default;

        @include mi-drop-shadow();

        &:after {
            position: absolute;
            top: 6rem;
            left: 0;
            content: "";
            height: 2rem;
            width: 15rem;
            background: $color-theme;
            z-index: -1;
        }

        &.left {
            float: right;

            &:after {
                left: 17rem;
            }
        }

        &.right {
            float: left;

            &:after {
                left: -9rem,
            }
        }

        .content__title {
            text-align: center;

            h3 {
                margin: 0;
            }
        }

        p {
            margin: 0;
            font-size: $font-size-s;
            font-weight: $font-weight-light;
        }

        ul {
            padding-left: $indent-base;
        }
    }

    .roadmap-item__date {
        display: block;
        height: 14rem;
        width: 14rem;
        margin: auto;
        padding-top: $indent-l;

        background-color: $color-default;
        text-align: center;
        line-height: $line-height-xs;
        border-radius: 50%;
        z-index: 999;

        @include mi-drop-shadow();

        h3 {
            font-size: 2.6rem;
        }

        p {
            font-size: $font-size-xl;
        }
    }

    &:after {
        position: absolute;
        bottom: -5.5rem;
        left: calc(50% - 0.75rem);
        width: 1rem;
        height: 17rem;
        content: "";
        background-color: $color-grey-60;
    }

    &:last-of-type {
        &:after {
            width: 0;
            height: 0;
        }
    }

    // colors
    &.vacancies {
        .roadmap-item__content {
            background-color: $color-theme;

            &:after {
                background: $color-theme;
            }
        }

        .roadmap-item__date {
            color: $color-theme;
        }
    }

    &.authorization {
        .roadmap-item__content {
            background-color: $color-theme-light;

            &:after {
                background: $color-theme-light;
            }
        }

        .roadmap-item__date {
            color: $color-theme-light;
        }
    }

    &.tests {
        .roadmap-item__content {
            background-color: $color-alert;

            &:after {
                background: $color-alert;
            }
        }

        .roadmap-item__date {
            color: $color-alert;
        }
    }

    &.connections {
        .roadmap-item__content {
            background-color: $color-alt-dark;

            &:after {
                background: $color-alt-dark;
            }
        }

        .roadmap-item__date {
            color: $color-alt-dark;
        }
    }

    &.reintegration {
        .roadmap-item__content {
            background-color: $color-alt;

            &:after {
                background: $color-alt;
            }
        }

        .roadmap-item__date {
            color: $color-alt;
        }
    }

    &.jobs {
        .roadmap-item__content {
            background-color: $color-grey-alt;

            &:after {
                background: $color-grey-alt;
            }
        }

        .roadmap-item__date {
            color: $color-grey-alt;
        }
    }

    @media (max-width: $screen-sm) {
        margin: $indent-m 0;

        &:after {
            left: 19.5%;
        }

        .roadmap-item__content {
            &.left {
                display: none !important;
            }

            &.right {
                display: block !important;
                margin-left: -2.5rem;
            }
        }
    }

    @media (max-width: $screen-xs) {
        &:after {
            width: 0;
            height: 0;
        }

        .roadmap-item__date {
            display: none;
        }

        .roadmap-item__content {
            &:after {
                width: 0;
                height: 0;
            }

            &.right {
                margin-left: 0;
            }
        }
    }
}

.roadmap__form {
    padding: $indent-base;
    margin: $indent-xl 0;
    background-color: $color-default;

    .form__fieldset {
        margin: 0;
        text-align: center;

        .subtext {
            font-size: $font-size-s;
        }
    }

    .form__item {
        textarea {
            height: 3rem;
        }
    }
}
