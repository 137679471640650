.is-hidden {
    visibility: hidden;
}

@media (max-width: $screen-xs) {
    .is-hidden--mobile {
        display: none;
    }
}

.is-loading {
    i {
        display: none;
        color: transparent;
    }

    &:before {
        display: none;
    }

    &:after {
        @extend .icomoon;

        content: "\e900" !important;
        position: absolute;
        top: 0;
        bottom: 0;
        left: $indent-m;
        width: 2.2rem;
        height: 2.2rem;
        margin: auto;
        font-size: 2.2rem;

        @include mi-spin;
    }
}

.is-loading--center {
    @extend .is-loading;

    margin: 10rem auto 0;

    &:before {
        left: 0;
        right: 0;
    }
}

.is-loading--center--small {
    @extend .is-loading--center;

    position: relative;
    min-height: $indent-xl;
    margin: $indent-m auto;
}

.is-loading-dots {
    position: relative;

    &:after {
        position: absolute;
        display: inline-block;
        animation: dots steps(1, end) 1s infinite;
        content: "";
    }
}
