

.list_item--small {
    @extend .list_item;

    @include mi-flexbox();

    .item__title {
        width: 100%;
        margin: 0 $indent-xs 0 0;
    }

    .item__subtitle {
        color: var(--color-theme);
        margin: 0 $indent-xs 0 0;
        align-self: flex-end;
    }

    .actions {
        width: auto !important;
    }
}
