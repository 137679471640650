
.table__fold-out {
    width: 100%;
    border-collapse: collapse;

    tbody {
        border-bottom: 1px solid $color-grey-20;

        &.first {
            border-top: 1px solid $color-grey-20;
        }
    }

    tr {
        display: block;

        background-color: $color-default;

        td {
            width: 33%;
            padding: $indent-xs $indent-s;
            font-size: $font-size-default;
            font-weight: $font-weight-regular;
            font-style: italic;

            border-collapse: collapse;

            .form__checkbox {
                display: block;

                .form__checkbox__label {
                    width: 2.4rem;
                    height: 2.4rem;
                }

                .checked_box {
                    pointer-events: none;
                    cursor: default;
                }
            }

            i {
                font-size: $font-size-s;

                &.disabled {
                    color: $color-grey-20;
                    pointer-events: none;
                    cursor: default;
                }
            }

            .icons {
                width: 20px;
                height: 20px;
                float: right;
                margin-top: 5px;

                cursor: pointer;
            }
            &.td_check {
                width: 10%;
            }

            &.td_data {
                width: 80%;
            }

            &.td_title {
                width: 90%;
            }
        }

        &.expanded {
            background: $color-grey-5;
        }
    }

    .details {
        tr {
            border: none;

            td {
                background: $color-grey-5;
                font-style: normal;
            }
        }
    }

    .disabled {
        color: $color-grey-20;
    }
}
