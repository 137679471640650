
.top_notification {
    padding: $indent-m 0;
    background-color: $color-cta;
    color: $color-default;
    text-align: center;

    .top_notification__text {
        display: block;
        margin-bottom: $indent-s;
    }

    .button--alt,
    .button--danger {
        margin: 0;
    }

    .icons {
        display: inline-block;
        margin-right: $indent-xs;
        margin-top: -3px;
    }

    @media (max-width: $screen-xs) {
        .top_notification__text {
            margin-bottom: $indent-xs;
        }

        .button--alt {
            margin-bottom: $indent-xs;
        }
    }
}
