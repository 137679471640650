

.modal--vacancy {
    @extend .modal;

    overflow-y: auto;

    .select {
        margin: 0 0 $indent-m;
    }

    .text-center {
        @media (max-width: $screen-xs) {
            span {
                display: block;
                word-break: break-word;
            }
        }
    }
    .modal__container {
        width: 73rem;
        text-align: left;
        color: $color-grey-80;

        h2 {
            color: $color-grey-60;
            text-align: center;
            text-transform: uppercase;
            font-size: $font-size-xl;

            span {
                color: var(--color-theme);
            }
        }

        h3 {

            margin-bottom: 0;
            color: $color-grey-60;
            text-align: left !important;
        }

        p {
            &:last-of-type {
                margin: 0 0 $line-height-m !important;
            }
        }
    }

    .modal--vacancy__expanded_block {
        margin-top: $indent-l;
    }

    .modal--vacancy__text {
        padding: $indent-s 0 0;

        i {
            font-style: normal;
        }

        br + br + br {
            display: none;
        }

        h2,
        h3 {

            margin-bottom: $indent-s;
            color: $color-grey-60;
            text-align: left !important;
        }

        p {
            text-align: left !important;
        }

        img {
            max-width: 100%;
        }
    }

    .modal--vacancy__table {
        margin: 0 0 $indent-s;
        padding: 0;
        border-bottom: 1px solid $color-border-light;
    }

    .modal--vacancy__list {
        li {
            br {
                display: none;
            }
        }
    }

    .modal--vacancy__form {
        margin-top: $indent-s;
        padding: $indent-m 0 0;
        border-top: 1px solid $color-border-light;

        h2 {
            margin-top: $indent-xs;
            text-align: left;
            color: var(--color-theme);
        }

        .motivation {
            max-width: 100%;
            hyphens: auto;
        }

    }

    .modal--vacancy__uploaded_file {
        margin: $indent-xs $indent-m 0 0;
        display: inline-block;
        padding: $indent-xs;
        background: $color-bg-darker;
        word-break: break-word;
    }

    .actions {
        padding: $indent-m 0;
        width: 100%;

        .actions__action--save {
            color: var(--color-cta-text);
            margin: 0;
        }

        .actions__action--delete {
            color: $color-alert;
        }

        .actions__action {
            margin-right: $indent-l;

            &.pull-right {
                margin-right: 0;
            }

            .actions__action--between {
                &.pull-right {
                    margin-right: $indent-l;
                }
            }
        }
    }

    @media (max-width: $screen-xs) {
        .modal__container {
            padding: $indent-m;
        }

        label br,
        p br {
            display: inline-block;
        }

        .modal--vacancy__table,
        .modal--vacancy__text,
        .modal--vacancy__form {
            margin: $indent-s 0 $indent-m;
            padding: 0;
        }

        .actions {
            margin-top: $indent-s;
            padding: 0 0 $indent-s;

            .actions__action {
                + .actions__action {
                    float: right;
                    margin: 0;
                }
            }

            .actions__action--save,
            .actions__action--between {
                display: block;
                float: none !important;
                margin: $indent-s 0;
                text-align: center;
            }
        }
    }
}
