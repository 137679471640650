

.page--vacancy {
    @extend .page;

    padding: 0;

    .select {
        margin: 0 0 $indent-m;
    }

    h2 {
        color: $color-grey-60;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-xl;

        span {
            color: var(--color-theme);
        }
    }

    h3 {
        @extend .heading-h2;

        margin-bottom: 0;
        color: $color-grey-60;
        text-align: left !important;
        font-size: $font-size-xl;
    }

    p {
        &:last-of-type {
            margin: 0 0 $line-height-m !important;
        }
    }

    .modal--vacancy__expanded_block {
        margin-top: $indent-l;
    }

    .modal--vacancy__text {
        padding: $indent-s 0 0;

        i {
            font-style: normal;
        }

        br + br + br {
            display: none;
        }

        h2,
        h3 {
            @extend .heading-h2;

            margin-bottom: $indent-s;
            color: $color-grey-60;
            text-align: left !important;
            font-size: $font-size-xl;
        }

        p {
            text-align: left !important;
        }
    }

    .modal--vacancy__table {
        margin: 0 0 $indent-s;
        padding: 0;
        border-bottom: 1px solid $color-border-light;
    }

    .modal--vacancy__form {
        margin-top: $indent-s;
        padding: $indent-m 0 0;
        border-top: 1px solid $color-border-light;

        h2 {
            margin-top: $indent-xs;
            text-align: left;
            color: var(--color-theme);
        }
    }

    .modal--vacancy__uploaded_file {
        margin: $indent-xs $indent-m 0 0;
        display: inline-block;
        padding: $indent-xs;
        background: $color-bg-darker;
    }

    .actions {
        padding: $indent-m 0;

        .actions__action--save {
            color: var(--color-cta-text);
            margin: 0;
        }

        .actions__action--delete {
            color: $color-alert;
        }

        .actions__action {
            margin-right: $indent-l;

            &.pull-right {
                margin-right: 0;
            }

            .actions__action--between {
                &.pull-right {
                    margin-right: $indent-l;
                }
            }
        }
    }

    @media (max-width: $screen-xs) {
        .modal--vacancy__uploaded_file {
            display: block;
            margin: $indent-xs 0 0;
        }

        label br,
        p br {
            display: inline-block;
        }

        .modal--vacancy__table,
        .modal--vacancy__text,
        .modal--vacancy__form {
            margin: $indent-s 0 $indent-m;
            padding: 0;
        }

        .actions {
            padding: 0 0 $indent-s;

            .actions__action {
                + .actions__action {
                    float: right;
                    margin: 0;
                }
            }

            .actions__action--save,
            .actions__action--between {
                display: block;
                float: none !important;
                margin: $indent-s 0;
                text-align: center;
            }
        }
    }
}
