
.diploma-self {
    .diploma-self-block {
        text-align: left;

        h2 {
            text-transform: none;
            font-size: $font-size-xl;
            font-weight: $font-weight-medium;
        }
        h3 {
            margin-bottom: $indent-xs;

            + p {
                margin-top: 0;
            }

            + .diploma-self-range {
                margin-top: $indent-m;

                .diploma-self-range-values {
                    margin-top: $indent-xs;
                    text-align: center;
                    font-size: $font-size-s;
                    overflow: hidden;

                    > div {
                        display: inline-block;

                        &:first-child {
                            position: absolute;
                            left: 0;
                            top: 0;
                        }

                        &:last-child {
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }
            }
        }
        + .diploma-self-block {
            margin-top: $indent-l;
        }
    }
    .icon-button {
        display: inline-block;
        margin: 0 0 0 $indent-xxs;
        padding: 0;
        line-height: 1;
        border: 0;
        font-size: $font-size-icon-xs;
        vertical-align: middle;

        @include mi-transition();

        &:hover,
        &:active {
            cursor: pointer;
            color: var(--color-theme-dark);
        }
    }
    .diploma-textarea {
        width: 100%;
        border: 1px solid $color-border-light;
        padding: $indent-m;
        border-radius: $border-radius;
        margin-top: $indent-xs;
        height: $indent-xxxl;
    }
}
