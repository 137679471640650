
.legend {
    padding: 0;

    li {
        position: relative;
        display: inline-block;
        list-style: none;
        width: auto;
        margin: 0;
        padding: 0 $indent-xxs $indent-xxs 0;
        white-space: nowrap;

        .dot {
            margin: 0 $indent-s 0 0;
            vertical-align: middle;
        }

        &.starter {
            color: $color-alert;
        }

        &.gevorderd {
            color: $color-text;
        }

        &.beroepsbekwaam {
            color: $color-success;
        }

        &.pending {
            color: $color-grey-20;
        }
    }
}
