
.checkbox_list {
    width: calc(100% + 2 * #{$indent-xxl});
    margin: 0 (-$indent-xxl);
    padding: 0;
    text-align: left;

    li {
        list-style: none;
        position: relative;
        display: block;
        font-size: $font-size-default;
        font-weight: $font-weight-regular;

        .form__checkbox {
            position: absolute;
            left: $indent-s;
            top: 1.4rem;
            width: 2.4rem;
            height: 2.4rem;
            margin: 0;
        }
    }

    > li {
        border-bottom: 1px solid $color-grey-20;
        background-color: $color-default;

        @include mi-transition();

        .checkbox_list__clickable {
            padding: $indent-s 0 $indent-s 6rem;
        }

        &:first-child {
            border-top: 1px solid $color-grey-20;
        }

        &.expanded {
            background: $color-default;

            li {
                background: transparent;
            }
        }

        &:hover,
        &:active {
            background: $color-grey-5;
            cursor: pointer;
        }

        &.disabled {
            background: $color-default;
            color: $color-grey-20;
            pointer-events: none;
            cursor: default;

            &:hover,
            &:active {
                background: $color-default;
                cursor: default;
            }
        }
    }

    .checkbox_list__sub {
        margin: 0 0 $indent-xs;
        padding: 0 0 0 $indent-xl;

        li {
            padding: $indent-xs 0 $indent-xs $indent-hor;
            background: $color-grey-5;

            .form__checkbox {
                left: 0;
                top: $indent-xs;
            }
        }
    }

    .checkbox_list__title {
        position: relative;
        padding-right: $indent-xl;
        font-style: italic;
    }

    .checkbox_list__fold_icon {
        position: absolute;
        right: $indent-s;
        top: 0.2rem;
        bottom: 0;
        width: 2rem;
        height: 2rem;
        margin: 0;
        font-size: $font-size-s;
    }

    .checkbox_list__text {
        display: block;
        padding-right: $indent-s;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .disabled {
        color: $color-grey-20;
    }

    @media (max-width: $screen-xs) {
        width: calc(100% + 2 * #{$indent-m});
        margin: 0 (-$indent-m);

        .checkbox_list__sub {
            li {
                padding-left: $indent-l;
            }
        }
    }
}
