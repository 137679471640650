

.button--add
{
    @extend .button--cta;

    display: inline-block;
    width: auto;
    margin: 0;
    padding-left: $indent-m;
    padding-right: $indent-m;
    border-radius: $border-radius 0 0 $border-radius;
}
