
.select {
    display: inline-block;
    width: auto;
    margin: $indent-s 0 0;
    background: $color-grey-10;
    border-radius: $border-radius;
    overflow: hidden;

    @include mi-transition();

    select {
        width: 100%;
        padding: $indent-xs $indent-xl $indent-xs $indent-s;
        background: transparent;
        border: 1px solid transparent;
        font-size: $font-size-s;

        @include mi-no-appearance;
        @include mi-transition();

        &:focus {
            border-color: $color-grey;
        }
    }

    .select__icon_down {
        position: absolute;
        top: $indent-xs;
        right: $indent-xs;
        color: $color-border;
        pointer-events: none;

        @include mi-transition();
    }

    &:hover,
    &:active {
        cursor: pointer;
        background: $color-grey-20;

        select {
            cursor: pointer;
        }
    }
}
