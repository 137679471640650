@import "settings.scss";
@import "mixins.scss";
@import "keyframes.scss";
@import "general.scss";
@import "states.scss";
@import "icons.scss";
@import "styleguide.scss";
@import "typography.scss";

@import "src/components/Legend/css/legend.scss";
@import "src/components/Pagination/css/pagination.scss";
@import "src/components/Footer/css/footer.scss";
@import "src/components/Page/css/page.scss";
@import "src/components/Page/css/page--ecosystem.scss";
@import "src/components/Page/css/page--vacancy.scss";
@import "src/components/Card/css/card.scss";
@import "src/components/Card/css/card--simple--auto_height.scss";
@import "src/components/Card/css/card--bg_img.scss";
@import "src/components/Card/css/card--simple.scss";
@import "src/components/Card/css/card--simple.scss";
@import "src/components/Card/css/card--last.scss";
@import "src/components/Card/css/card--list.scss";
@import "src/components/Card/css/card--back.scss";
@import "src/components/Forms/css/form.scss";
@import "src/components/Forms/css/form--bg.scss";
@import "src/components/Forms/css/form--login.scss";
@import "src/components/Forms/css/form--small_margin.scss";
@import "src/components/Forms/css/form--panel.scss";
@import "src/components/Loading/css/loading.scss";
@import "src/components/Panel/css/panel.scss";
@import "src/components/Panel/css/panel--expand.scss";
@import "src/components/Panel/css/panel--diplomas.scss";
@import "src/components/Panel/css/panel--multi.scss";
@import "src/components/Panel/css/panel--sharing.scss";
@import "src/components/Panel/css/panel--quote.scss";
@import "src/components/Panel/css/panel--map.scss";
@import "src/components/Panel/css/panel--security.scss";
@import "src/components/Panel/css/panel--bg.scss";
@import "src/components/Panel/css/panel--suggestion.scss";
@import "src/components/Panel/css/panel--vacancy.scss";
@import "src/components/Panel/css/panel--events.scss";
@import "src/components/Panel/css/panel--flex.scss";
@import "src/components/Dot/css/dot.scss";
@import "src/components/Intro/css/Intro.scss";
@import "src/components/EcosystemPie/css/ecosystem_pie.scss";
@import "src/components/Suggestion/css/suggestion.scss";
@import "src/components/CheckboxList/css/checkbox_list.scss";
@import "src/components/Quote/css/quote.scss";
@import "src/components/DropdownMenu/css/dropdown.scss";
@import "src/components/DropdownMenu/css/dropdown__option.scss";
@import "src/components/DropdownMenu/css/dropdown--large.scss";
@import "src/components/Alert/css/alert.scss";
@import "src/components/Alert/css/alert--box--error.scss";
@import "src/components/Alert/css/alert--criteria.scss";
@import "src/components/Alert/css/alert--box.scss";
@import "src/components/Alert/css/alert--box--success.scss";
@import "src/components/Alert/css/alert--success.scss";
@import "src/components/Alert/css/alert--error.scss";
@import "src/components/Alert/css/alert--simple.scss";
@import "src/components/NavigationDrawer/css/navigation_drawer.scss";
@import "src/components/Toast/css/toast-animate.scss";
@import "src/components/Toast/css/toast.scss";
@import "src/components/Graph/css/graph__container.scss";
@import "src/components/ListProfile/css/list_profile.scss";
@import "src/components/ListItem/css/list_item.scss";
@import "src/components/ListItem/css/list_item--full_width.scss";
@import "src/components/ListItem/css/list_item--narrow.scss";
@import "src/components/ListItem/css/list_item--small.scss";
@import "src/components/HorizontalMenu/css/menu--horizontal.scss";
@import "src/components/DataBlock/css/data_block.scss";
@import "src/components/PasswordRequirements/css/password_requirements.scss";
@import "src/components/FileLister/css/filelister.scss";
@import "src/components/Button/css/button.scss";
@import "src/components/Button/css/button--cancel.scss";
@import "src/components/Button/css/button--link.scss";
@import "src/components/Button/css/button--small.scss";
@import "src/components/Button/css/button--add.scss";
@import "src/components/Button/css/button--cta.scss";
@import "src/components/Button/css/button--expand.scss";
@import "src/components/Button/css/button--alt.scss";
@import "src/components/Button/css/button--icon.scss";
@import "src/components/Button/css/button--back.scss";
@import "src/components/Button/css/button--link--alert.scss";
@import "src/components/Button/css/button--danger.scss";
@import "src/components/Button/css/button--alert.scss";
@import "src/components/IconCheck/css/icon_check.scss";
@import "src/components/Profile/Reintegration/css/clientvolgsyteem.scss";
@import "src/components/Dropzone/css/dropzone.scss";
@import "src/components/Table/css/table__extra-margin.scss";
@import "src/components/Table/css/table__fold-out.scss";
@import "src/components/DiplomaSelfEval/css/score_board.scss";
@import "src/components/DiplomaSelfEval/css/diploma-self.scss";
@import "src/components/Actions/css/actions.scss";
@import "src/components/DiplomaTable/css/diploma-table.scss";
@import "src/components/Select/css/select.scss";
@import "src/components/Select/css/select--single.scss";
@import "src/components/Select/css/select--solo.scss";
@import "src/components/Select/css/select--alt.scss";
@import "src/components/Select/css/select--alt-black.scss";
@import "src/components/FileUploader/css/file_uploader.scss";
@import "src/components/Modal/css/modal.scss";
@import "src/components/Modal/css/modal--delete.scss";
@import "src/components/Modal/css/modal--vacancy.scss";
@import "src/components/Modal/css/modal--scroll.scss";
@import "src/components/Modal/css/modal--resume.scss";
@import "src/components/Modal/css/modal--form.scss";
@import "src/components/TopNotification/css/top_notification.scss";
@import "src/components/MultiSelect/css/multi_select.scss";
@import "src/components/Event/css/event.scss";
@import "src/components/Tag/css/tag.scss";
@import "src/components/FileInput/css/file_input.scss";
@import "src/components/Header/css/header.scss";
@import "src/components/Header/css/sidebar.scss";
@import "src/components/BulletList/css/bullet_list.scss";
@import "src/components/Onboarding/css/onboarding.scss";
@import "src/components/RoadmapItem/css/roadmap-item.scss";
@import "src/components/Datepicker/css/datepicker.scss";
