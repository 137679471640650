

.button--link--alert
{
    @extend .button--link;

    color: $color-alert;

    &:hover,
    &:active
    {
        color: $color-alert-hover;
    }
}
