
.header {
    .hamburger_icon_container {
        display: inline-block;
        cursor: pointer;
        margin-right: $indent-s;
        margin-top: 1.7rem;

        .bar1, .bar2, .bar3 {
            width: 30px;
            height: 3px;
            background-color: var(--color-theme);
            margin: 5px 0;
            transition: 0.4s;
        }
        &.change {
            .bar1 {
                -webkit-transform: rotate(-45deg) translate(-6px, 6px);
                transform: rotate(-45deg) translate(-6px, 6px);
            }
            .bar2 {opacity: 0;}

            .bar3 {
                -webkit-transform: rotate(45deg) translate(-5px, -5px);
                transform: rotate(45deg) translate(-5px, -5px);
            }
        }
    }

    @media (max-width: $screen-xs) {
        .hamburger_icon_container {
            position: absolute;
            top: 0;
            left: $indent-base;
        }
    }
}
