//Spin 360 degrees continuously
@keyframes spin {
    from {
        @include mi-rotate(0deg);
    }
    to {
        @include mi-rotate(360deg);
    }
}

//Add three dots
@keyframes dots {
    0% {
        content: "";
    }
    25% {
        content: ".";
    }
    50% {
        content: "..";
    }
    75% {
        content: "...";
    }
    100% {
        content: "";
    }
}
