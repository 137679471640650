
.alert {
    width: 100%;
    height: auto;
    margin: $indent-m 0;
    padding: $indent-xs $indent-xl;
    background: $color-bg;
    color: $color-text;
    font-weight: $font-weight-regular;
    text-align: center;

    ul {
        padding-left: $indent-hor;
        text-align: left;
    }

    @media (max-width: $screen-xs) {
        padding: $indent-xs $indent-base;
    }
}
