
.header {
    height: $header-height;
    background: $color-default;

    @include mi-drop-shadow(0, 1px, 3px, 0, 0.3);

    .header__logo {
        position: relative;
        float: left;
        margin-top: 1.2rem;
        display: inline-block;
        z-index: 1;
        height: 36px;

        img {
            vertical-align: middle;
        }
    }

    #header__logo {
        content: var(--logo-image);
        max-width: 190px;
        max-height: 36px;
    }

    @media (max-width: $screen-xs) {
        text-align: center;

        .header__logo {
            float: none !important;
        }
    }
}
