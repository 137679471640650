

.page--ecosystem
{
    text-align: center;

    h1
    {
        color: var(--color-theme);

        + p
        {
            font-size: $font-size-l;
        }
    }

    p
    {
        img
        {
            height: 6.4rem;
            margin:  0 $indent-s $indent-s;
        }
    }

    @media (max-width: $screen-md)
    {
        p
        {
            margin: $indent-l 0 0;

            img
            {
                height: 3.6rem;
            }
        }
    }
}
