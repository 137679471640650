

.form--small_margin {
    @extend .form;

    .form__header {
        p {
            text-align: center;
            font-weight: $font-weight-regular;
        }
    }

    hr {
        margin: $indent-m (-$indent-xl);
        border: none;
        height: 0.2rem;
        background-color: $color-grey-10;
    }

    .form__input--text {
        width: calc(100% - 2 * #{$indent-m});
        margin: 0 auto $indent-l;
        display: block;
        text-align: center;
    }

    .form__items {
        margin: $indent-xs (-$indent-xxl);
        height: auto;
        max-height: 40rem;
        overflow-y: auto;
        overflow-x: hidden;

        .form__item {
            margin: 0;
            padding: $indent-m;

            .form__checkbox {
                margin: 0;
            }

            label {
                font-weight: $font-weight-medium;
                font-style: italic;
            }
            p {
                margin: 0;
                padding: $indent-xs 0 0 3.5rem;
                font-weight: $font-weight-regular;

                &:empty {
                    display: none;
                }
            }
        }

        .form__item--selected {
            background-color: $color-grey-5;
        }

        &::-webkit-scrollbar-track {
            background-color: $color-grey-5;
            border-radius: $border-radius-l;
            -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.05);
        }

        &::-webkit-scrollbar {
            width: 0.8rem;
            background-color: inherit;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-10;
            border-radius: $border-radius-l;
            -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.05);
        }
    }

    .button--cta {
        margin-top: $indent-l !important;
    }

    @media (max-width: $screen-xs) {
        margin: 0 (-$indent-m);

        .form__fieldset {
            width: 100%;
        }

        .form__items {
            margin: $indent-xs;

            .form__item {
                width: 100%;
                padding: $indent-l $indent-s 0 !important;

                .form__checkbox {
                    width: 100%;

                    .form__checkbox__label {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        hr {
            width: 100%;
            margin: $indent-m 0;
        }
    }
}
