

.table__cell {
    text-align: left;
}

.table__cell-sm {
    width: 30%;
}

.table__cell-bg {
    max-width: 70%;
}

.form__input--text-sm {
    width: 90% !important;
}

.panel--multi .panel__table tr td,
.panel--multi .panel__table tr th:last-child,
.panel--multi .panel__table tr td:last-child {
    text-align: center;
}

.table__cell-hover {
    cursor: pointer;
}

.text-newline {
    white-space: pre-line;
}
.actions__action--view {
    &:before {
        top: auto !important;
        margin: auto !important;
    }
}
