.styleguide-header {
    margin: 0 0 $indent-base;
    padding: $indent-m;
    border-top: 1px solid $color-grey-40;
    border-bottom: 1px solid $color-grey-40;
    background: $color-theme;
    color: $color-default;
}

h1.styleguide-header {
    margin-top: $indent-l;
}

.styleguide-container {
    width: 100%;
    margin: $indent-xs 0 $indent-l;
    padding: $indent-m 0;

    @extend .clearfix;

    .icons {
        font-size: 3rem;
    }

    .button--icon {
        margin-right: 1rem;
    }
}

.styleguide-container--bg {
    @extend .styleguide-container;

    background: $color-theme;
    padding: $indent-m;
}

.styleguide-color {
    position: relative;
    width: 4rem;
    height: 4rem;
    margin: 0 2rem 2rem 0;
    float: left;
    border-radius: 50%;
    border: 1px solid $color-grey-20;

    span {
        display: none;
        position: absolute;
        top: 130%;
        width: auto;
        height: auto;
        padding: 5px 10px 3px;
        font-size: 12px;
        text-align: center;
        color: $color-default;
        border-radius: $border-radius;
        white-space: nowrap;
        background: $color-grey-80;
    }

    &:hover,
    &:active {
        cursor: pointer;

        span {
            display: block;
        }
    }

    &.color-theme {
        background: $color-theme;
    }

    &.color-theme-light {
        background: $color-theme-light;
    }

    &.color-theme-dark {
        background: $color-theme-dark;
    }

    &.color-default {
        background: $color-default;
    }

    &.color-alert {
        background: $color-alert;
    }

    &.color-alt {
        background: $color-alt;
    }

    &.color-grey {
        background: $color-grey;
    }

    &.color-grey-80 {
        background: $color-grey-80;
    }

    &.color-grey-60 {
        background: $color-grey-60;
    }

    &.color-grey-40 {
        background: $color-grey-40;
    }

    &.color-grey-20 {
        background: $color-grey-20;
    }

    &.color-grey-10 {
        background: $color-grey-10;
    }

    &.color-grey-5 {
        background: $color-grey-5;
    }
}
