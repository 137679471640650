//GRADIENT
@mixin mi-gradient($startColor, $endColor) {
    background-color: $endColor;
    background-image: linear-gradient(to bottom, $startColor 0%, $endColor 100%);
    background-image: -o-linear-gradient(top, $startColor, $endColor);
    background-image: -ms-linear-gradient(top, $startColor, $endColor);
    background: -moz-linear-gradient(top, $startColor, $endColor);
    background: -webkit-linear-gradient(top, $startColor, $endColor);
}

//GRADIENT
@mixin mi-gradient-horizontal($startColor, $endColor) {
    background-color: $startColor;
    background-image: -o-linear-gradient(left, $startColor, $endColor);
    background-image: -ms-linear-gradient(left, $startColor, $endColor);
    background-image: linear-gradient(to right, $startColor, $endColor);
    background: -moz-linear-gradient(left, $startColor, $endColor);
    background: -webkit-linear-gradient(left, $startColor, $endColor);
}

//GRADIENT
@mixin mi-gradient-vertical($startColor, $endColor) {
    background-color: $startColor;
    background-image: -o-linear-gradient(bottom, $startColor, $endColor);
    background-image: -ms-linear-gradient(bottom, $startColor, $endColor);
    background-image: linear-gradient(to top, $startColor, $endColor);
    background: -moz-linear-gradient(bottom, $startColor, $endColor);
    background: -webkit-linear-gradient(bottom, $startColor, $endColor);
}

@mixin mi-gradient-radial($centerPos, $startColor, $endColor) {
    background-color: $startColor;
    background-image: -o-radial-gradient(circle at $centerPos, $startColor, $endColor);
    background-image: -ms-radial-gradient(circle at $centerPos, $startColor, $endColor);
    background-image: radial-gradient(circle at $centerPos, $startColor, $endColor);
    background: -moz-radial-gradient(circle at $centerPos, $startColor, $endColor);
    background: -webkit-radial-gradient(circle at $centerPos, $startColor, $endColor);
}

//GRADIENT
@mixin mi-gradient-top-right($startColor, $middleColor, $endColor) {
    background-color: $startColor;
    background: -moz-linear-gradient(bottom left, $startColor, $middleColor, $endColor);
    background: -webkit-linear-gradient(bottom left, $startColor, $middleColor, $endColor);
    background-image: -o-linear-gradient(bottom left, $startColor, $middleColor, $endColor);
    background-image: -ms-linear-gradient(bottom left, $startColor, $middleColor, $endColor);
    background-image: linear-gradient(to top right, $startColor, $middleColor, $endColor);
}

//DROP SHADOW
@mixin mi-drop-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.2) {
    -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
    -moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
    box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin mi-drop-shadow-color($x: 0, $y: 1px, $blur: 2px, $spread: 0, $color: $color-grey-20) {
    -webkit-box-shadow: $x $y $blur $spread $color;
    -moz-box-shadow: $x $y $blur $spread $color;
    box-shadow: $x $y $blur $spread $color;
}

//INNER SHADOW
@mixin mi-inner-shadow($x: 0, $y: 3px, $blur: 5px, $spread: 0, $alpha: 0.25) {
    -webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
    -moz-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
    box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin mi-inner-shadow-color($x: 0, $y: 1px, $blur: 2px, $spread: 0, $color: $color-grey-20) {
    -webkit-box-shadow: inset $x $y $blur $spread $color;
    -moz-box-shadow: inset $x $y $blur $spread $color;
    box-shadow: inset $x $y $blur $spread $color;
}

@mixin mi-shadow-color($x: 0, $y: 0, $blur: 8px, $spread: 0, $color: $color-theme) {
    -webkit-box-shadow: $x $y $blur $spread $color;
    -moz-box-shadow: $x $y $blur $spread $color;
    box-shadow: $x $y $blur $spread $color;
}

//NO BOX SHADOW
@mixin mi-no-shadow {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

//Opacity
@mixin mi-opacity($opacity: 0.8) {
    -webkit-opacity: $opacity;
    -moz-opacity: $opacity;
    opacity: $opacity;
}

//Rotate
@mixin mi-transform($settings) {
    -moz-transform: $settings;
    -ms-transform: $settings;
    -o-transform: $settings;
    -webkit-transform: $settings;
    transform: $settings;
}

@mixin mi-rotate($deg) {
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -o-transform: rotate($deg);
    -webkit-transform: rotate($deg);
    transform: rotate($deg);
}

//Origin
@mixin mi-transform-origin($hor, $ver) {
    -webkit-transform-origin: $hor $ver;
    -moz-transform-origin: $hor $ver;
    -ms-transform-origin: $hor $ver;
    -o-transform-origin: $hor $ver;
    transform-origin: $hor $ver;
}

//Rotate and translate
@mixin mi-rotate-translate($deg, $x, $y) {
    -moz-transform: rotate($deg) translate($x, $y);
    -ms-transform: rotate($deg) translate($x, $y);
    -o-transform: rotate($deg) translate($x, $y);
    -webkit-transform: rotate($deg) translate($x, $y);
    transform: rotate($deg) translate($x, $y);
}

//Scale
@mixin mi-scale($scale-x: 1.02, $scale-y: 1.02) {
    -ms-transform: scale($scale-x, $scale-y); /* IE 9 */
    -webkit-transform: scale($scale-x, $scale-y); /* Safari */
    transform: scale($scale-x, $scale-y);
    transform-origin: center center;
    -webkit-transform-origin: center center;
}

//Transition
@mixin mi-transition($trans_time: 0.2s) {
    -moz-transition: all $trans_time linear;
    -webkit-transition: all $trans_time linear;
    -o-transition: all $trans_time linear;
    transition: all $trans_time linear;
}

//Sharper resized images
@mixin mi-image-crisp {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: bicubic;
}

//Keyframes
@mixin mi-animation($kf, $duration, $timing, $iterationCount) {
    -webkit-animation: $kf $duration $timing $iterationCount;
    -moz-animation: $kf $duration $timing $iterationCount;
    -ms-animation: $kf $duration $timing $iterationCount;
    -o-animation: $kf $duration $timing $iterationCount;
    animation: $kf $duration $timing $iterationCount;
}

@mixin mi-spin {
    @include mi-animation(spin, 1s, linear, infinite);
}

//No appearance
@mixin mi-no-appearance {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

//Clearfix
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.clearfix {
    display: inline-block;
}

@mixin mi-icomoon-font {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin mi-icons-font {
    font-family: $font-icons;
    speak: none;
    font-size: $font-size-icon-s;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin mi-flexbox() {
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
}

@mixin mi-flex($width) {
    -moz-box-flex: $width;
    -webkit-flex: $width;
    -ms-flex: $width;
    flex: $width;
}

@mixin mi-flex-direction($direction) {
    -ms-flex-direction: $direction;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin mi-flex-justify-content($setting){
    @include ms-flex-justify-content($setting); // IE10
    -webkit-justify-content: $setting;
    justify-content: $setting;
}

@mixin mi-flex-align-content($setting){
    @include ms-flex-align-content($setting); // IE10
    -webkit-align-content: $setting;
    align-content: $setting;
}

@mixin mi-flex-direction($direction) {
    -ms-flex-direction: $direction;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin mi-align-self($position) {
    @include ms-flex-align-self($position); // IE10
    -webkit-align-self: $position;
    align-self: $position;
}

@mixin mi-align-items($position) {
    @include ms-flex-align-items($position); // IE10
    -webkit-align-items: $position;
    align-items: $position;
}

@mixin mi-flex-wrap($setting) {
    -ms-flex-wrap: $setting;
    -webkit-flex-wrap: $setting;
    flex-wrap: $setting;
}

/* These are the conditional mixins for the different syntax for IE10 Flexbox */
@mixin ms-flex-align-items($alignItems) {
    @if $alignItems == 'flex-start' {
        -ms-flex-align: start;
    } @else if($alignItems == 'flex-end') {
        -ms-flex-align: end;
    } @else if($alignItems == 'baseline') {
        -ms-flex-align: $alignItems;
    } @else if($alignItems == 'stretch') {
        -ms-flex-align: $alignItems;
    }
}
@mixin ms-flex-justify-content($justifyContent) {
    @if $justifyContent == 'space-between' {
        -ms-flex-pack: justify;
    } @else if($justifyContent == 'space-around') {
        -ms-flex-pack: distribute;
    } @else if($justifyContent == 'flex-start') {
        -ms-flex-pack: start;
    } @else if($justifyContent == 'flex-end') {
        -ms-flex-pack: end;
    } @else if($justifyContent == 'center') {
        -ms-flex-pack: center;
    }
}

@mixin ms-flex-align-content($alignContent) {
    @if $alignContent == 'space-between' {
        -ms-flex-line-pack: justify;
    } @else if($alignContent == 'space-around') {
        -ms-flex-line-pack: distribute;
    } @else if($alignContent == 'flex-start') {
        -ms-flex-line-pack: start;
    } @else if($alignContent == 'flex-end') {
        -ms-flex-line-pack: end;
    } @else if($alignContent == 'center') {
        -ms-flex-line-pack: $alignContent;
    }  @else if($alignContent == 'stretch') {
        -ms-flex-line-pack: $alignContent;
    }
}
@mixin ms-flex-align-self($alignSelf) {
    @if(-$alignSelf == 'flex-start') {
        -ms-flex-item-align: start;
    } @else if(-$alignSelf == 'flex-end') {
        -ms-flex-item-align: end;
    } @else if(-$alignSelf == 'center') {
        -ms-flex-item-align: -$alignSelf;
    } @else if(-$alignSelf == 'stretch') {
        -ms-flex-item-align: -$alignSelf;
    } @else if(-$alignSelf == 'auto') {
        -ms-flex-item-align: -$alignSelf;
    } @else if(-$alignSelf == 'baseline') {
        -ms-flex-item-align: -$alignSelf;
    }
}
