

.modal--resume {
    @extend .modal;

    overflow-y: auto;

    .modal__container {
        padding: 0;
        border-radius: 0.4rem;

        .panel {
            margin: 0;
            box-shadow: none;

            .panel__header {
                border-radius: 0;
            }

            .panel__body,
            .data_block {
                text-align: left;
            }

            h2 {
                color: $color-default;
                text-align: left;
                text-transform: uppercase;
                font-size: $font-size-xl;
            }

            p {
                margin: $line-height-m 0 0;
                font-size: $font-size-default;
                line-height: $line-height-default;
                font-weight: $font-weight-regular;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .actions {
                .actions__action--save {
                    color: var(--color-cta-text);
                    margin: 0;
                }

                .actions__action {
                    margin-right: $indent-l;
                }
            }
        }
    }
}
