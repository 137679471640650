

.card--list {
    @extend .card;

    .card__header,
    .card__subheader {
        margin: 0;
        line-height: 1.25;
        font-family: var(--font-header);;
        font-size: 4rem;
        font-weight: bold;
        color: var(--color-theme);
        text-transform: uppercase;
    }

    .card__subheader {
        color: $color-grey-60;
    }

    ul {
        display: inline-block;
        margin: $indent-xl 0 0;
        padding: 0;
        text-align: left;

        li {
            list-style: none;
            position: relative;
            padding-left: 3.5rem;
            color: $color-grey-80;
            font-weight: $font-weight-medium;

            .icons {
                position: absolute;
                left: 0;
                top: 2px;
                width: 2rem;
                text-align: center;
                font-size: $font-size-default;
                color: var(--color-theme);
            }
        }
    }

    @media (max-width: $screen-md) {
        .card__header,
        .card__subheader {
            font-size: 3.6rem;
        }
    }

    @media (max-width: $screen-xs) {
        .card__header,
        .card__subheader {
            font-size: 2.6rem;
            line-height: 3.5rem;
        }

        ul {
            margin-top: $indent-m;
        }
    }

    @media (max-width: $screen-xxs) {
        .card__header,
        .card__subheader {
            font-size: 2rem;
            line-height: 1.5;
        }
    }
}
