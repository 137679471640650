
$icon-check-dim: 1.3rem;

.icon_check {
    display: block;
    width: $icon-check-dim;
    height: $icon-check-dim;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);

    &:before {
        content: "";
        position: absolute;
        left: 0.4rem;
        top: 0.1rem;
        display: block;
        width: 0.5rem;
        height: 0.8rem;
        margin: auto;
        border-right: 1px solid $color-grey-60;
        border-bottom: 1px solid $color-grey-60;

        @include mi-rotate(45deg);
    }

    &.is-active {
        background: $color-success;

        &:before {
            border-right-color: $color-default;
            border-bottom-color: $color-default;
        }
    }
}
