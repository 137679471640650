

.alert--box--error {
    @extend .alert--box;

    @extend .alert--box;

    border-color: $color-alert !important;
    color: $color-alert;

    &:before {
        content: "\e617";
        color: $color-alert !important;
    }
}
