

.button--link {
    @extend .button;

    background: transparent;
    border-color: transparent;
    color: inherit;

    @include mi-no-shadow;

    > span {
        padding: 0 0.5rem;
    }

    .mdi-arrow-left {
        position: relative;
        float: left;
        left: 0;
        margin: 0 $indent-xs 0 0;
        font-size: $font-size-icon;
        line-height: $line-height-m;

        @include mi-transition(0.1s);
    }

    &:hover,
    &:active {
        border-color: transparent;
        color: inherit;
        text-decoration: underline;

        @include mi-no-shadow;

        .mdi-arrow-left {
            left: -0.5rem;
        }
    }

    &:focus {
        color: inherit;
        border-color: transparent;
        text-decoration: none;

        > span {
            @include mi-shadow-color(0, 0, 0, 1px, $color-grey);
        }
    }

    &.grey {
        color: $color-grey-60;
    }

    &.purple {
        color: var(--color-theme);
    }
}
