
.alert--criteria {
    padding: $indent-s $indent-l;
    color: $color-default;
    border-radius: $border-radius;
    background: $color-grey-10;
    text-align: left;

    p {
        margin: 0 0 $indent-xs;
    }

    ul {
        display: block;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            list-style: none;
            display: block;
            padding: 0 0 0 $indent-l;
            font-size: $font-size-default;
            vertical-align: top;
        }
    }

    .icon_check {
        position: absolute;
        left: 0;
        top: 0.6rem;
    }
}
