

.list_item--narrow {
    @extend .list_item;

    display: block;

    .actions {
        padding-left: 0;
        padding-right: 0;
        text-align: left;

        .actions__action--edit,
        .actions__action--delete {
            margin: 0;
        }

        .actions__action--delete {
            float: right;
        }
    }
}
