
.dropzone {
    .dropzone__text {
        margin: $indent-s 0 0;
    }

    .dropzone__input {
        width: 100%;
        padding: $indent-xl $indent-m;
        border: 1px dashed $color-border-light;
    }
}
