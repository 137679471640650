.pagination-container {
    text-align: center;
    width: fit-content;
    margin: 1rem auto;

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;

        a {
            display: inline-block;
            width: 3.25rem;
            height: 3.25rem;
            line-height: 3.25rem;
            margin: 0 0.5rem;
            background-color: #eee;
            text-align: center;
            border-radius: 100%;
            text-decoration: none;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
            display: none;
        }

        &.selected,
        &:hover {
            a {
                background-color: var(--color-theme);
                color: $color-default;
            }
        }

        &.next, &.previous {
            a {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 10rem;
                border-radius: 0.5rem;
                padding: 0 1rem;
            }
        }

        &.next {
            a {
                right: -11rem;
            }
        }

        &.previous {
            a {
                left: -11rem;
            }
        }
    }

    @media (max-width: $screen-sm) {
        li {
            &.next, &.previous {
                a {
                    width: 3.25rem;
                    height: 3.25rem;
                    border-radius: 100%;
                    padding: 0;
                }
            }

            &.next {
                a {
                    right: -4.75rem;
                }
            }

            &.previous {
                a {
                    left: -4.75rem;
                }
            }
        }
    }
}
