
.password_requirements {
    margin: $indent-l 0 $indent-m $indent-l;
    padding: $indent-s;
    background: $color-grey-5;

    ul {
        list-style-type: none;
        margin: $indent-xxs 0 $indent-xxs 0;
        padding: 0;

        li {
            padding-left: $indent-s;
            line-height: $line-height-m;
            color: $color-text-lighter;
            font-size: $font-size-default;
            clear: both;

            &.condition_met {
                color: $color-text;

                &:before {
                    color: $color-success;
                }
            }
            &:before
            {
                font-family: $font-icons-alt;
                font-size: $font-size-default;
                content: "\F5E0";
                float: left;
                width: $indent-m;
                margin-right: $indent-xs;
                color: $color-text-light;
            }
        }
    }
}

