

.button--small {
    @extend .button;

    width: auto;
    padding: 1rem $indent-base 0.6rem;
    background: $color-grey-10;
    color: $color-grey-80;
    border-radius: $indent-m;

    &:focus {
        outline: none;
        @include mi-shadow-color(0, 0, 0, 1px, $color-grey);
    }
}
