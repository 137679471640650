
.dot
{
    position: relative;
    display: inline-block;
    width: $indent-xs;
    height: $indent-xs;

    &.pointer {
        cursor: pointer;
    }

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        display: block;
        width: $indent-xs;
        height: $indent-xs;
        border-radius: 50%;
        background: $color-text;
    }

    &.starter {
        &:before {
            background: $color-alert;
        }
    }

    &.advanced {
        &:before {
            background:  $color-text;
        }
    }

    &.professional {
        &:before {
            background: $color-success;
        }
    }

    &.not-invited {
        &:before {
            background: $color-grey-20;
        }
    }

    &.pending {
        &:before {
            background: transparent;
            border: 1px solid $color-grey-40;
        }
    }
}
