
$es-dim-core: 30rem;
$es-dim-core-mobile: 20rem;
$es-slices: 8;
$es-rotation: 360deg / $es-slices;
$es-skew: $es-rotation + 90;

.ecosystem_pie
{
    position: relative;
    margin: $indent-xl auto 0;
    padding: 0;
    width: 100%;
    overflow: hidden;

    .ecosystem_pie__slice
    {
        display: none;
    }

    .ecosystem_pie__text
    {
        color: $color-default;

        h3
        {
            margin: 0 0 $indent-xxs;
            font-family: var(--font-default);;
            font-size: 2rem;
            font-weight: $font-weight-medium;
            line-height: 1;
            text-align: left;
            text-shadow: 0 1px 3px rgba(0,0,0,0.3);

            @include mi-flexbox();
            @include mi-align-items(center);
            @include mi-flex-justify-content(center);

            img
            {
                display: inline-block;
                margin: 0 $indent-s 0 0;
            }
        }

        p
        {
            display: block;
            margin: 0;
            font-size: 1.8rem;
            font-weight: $font-weight-regular;
            text-shadow: 0 1px 3px rgba(0,0,0,0.3);
        }

        img
        {
            display: block;
            height: 3.5rem;
            margin: 0 auto;
            vertical-align: top;
        }

        .ecosystem_pie__center
        {
            display: none;
        }
    }

    .ecosystem_pie__text--1,
    .ecosystem_pie__text--2,
    .ecosystem_pie__text--3,
    .ecosystem_pie__text--4,
    .ecosystem_pie__text--5,
    .ecosystem_pie__text--6,
    .ecosystem_pie__text--7,
    .ecosystem_pie__text--8
    {
        @extend .ecosystem_pie__text;
    }

    .ecosystem_pie__center
    {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: $es-dim-core;
        height: $es-dim-core;
        margin: auto;
        border: 1px solid transparent;
        border-radius: 50%;
        background: $color-bg-darker;
        z-index: 12;


        &:before
        {
            content: "";
            position: absolute;
            top: -$indent-l;
            right: -$indent-l;
            bottom: -$indent-l;
            left: -$indent-l;
            display: block;
            border-radius: 50%;

            @include mi-gradient-radial(top $indent-xl left $indent-xl, rgba(224, 224, 224, 0.81) 0, rgba(234, 234, 234, 0.24) 100%);
            @include mi-drop-shadow-color(0, 1px, 6px, 0, #686768);

        }
    }

    .ecosystem_pie__center__link
    {
        position: relative;
        display: block;
        width: calc(100% - 2 * #{$indent-l});
        height: calc(100% - 2 * #{$indent-l});
        margin: $indent-l;
        border-radius: 50%;
        text-align: center;

        @include mi-gradient-radial(top $indent-xl left $indent-xl, $color-default 0, #a8bcc1 100%);
        @include mi-drop-shadow-color(0, 1px, 5px, 0, #686768);

        img
        {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    @media (min-width: ($screen-md + 1))
    {
        border-radius: 50%;

        &:after
        {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        .ecosystem_pie__inner
        {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
        }

        .ecosystem_pie__slice
        {
            display: block;
            position: absolute;
            top: -50%;
            right: -50%;
            width: 100%;
            height: 100%;
            padding: $indent-base;

            @include mi-transform-origin(0, 100%);

            &:hover
            {
                z-index: 10;
                cursor: pointer;

                + div
                {
                    @include mi-scale(1.03);
                }
            }

            &--1 {
                @include mi-transform(rotate(3.5 * (-$es-rotation)) skewY($es-skew));

                .ecosystem_pie__slice__contents
                {
                    @include mi-gradient-radial(bottom 0 left 0, #e48108 3%, #e93c3c 15%);
                }
            }

            &--2 {
                @include mi-transform(rotate(2.5 * (-$es-rotation)) skewY($es-skew));

                .ecosystem_pie__slice__contents
                {
                    @include mi-gradient-radial(bottom 0 left 0, #fd9e09 3%, #f7c22a 15%);
                }
            }

            &--3 {
                @include mi-transform(rotate(1.5 * (-$es-rotation)) skewY($es-skew));

                .ecosystem_pie__slice__contents
                {
                    @include mi-gradient-radial(bottom 0 left 0, fade(#28c8a0,80%) 7%, #5abf1a 18%);
                }
            }

            &--4 {
                @include mi-transform(rotate(0.5 * (-$es-rotation)) skewY($es-skew));

                .ecosystem_pie__slice__contents
                {
                    @include mi-gradient-radial(bottom 0 left -2%, fade(#20b8f0,80%) 5%, #175fe9 17%);
                }
            }

            &--5 {
                @include mi-transform(rotate(0.5 * $es-rotation) skewY($es-skew));

                .ecosystem_pie__slice__contents
                {
                    @include mi-gradient-radial(bottom 0 left 0, #469bb9 0, #1f35bc 15%);
                }
            }

            &--6 {
                @include mi-transform(rotate(1.5 * $es-rotation) skewY($es-skew));

                .ecosystem_pie__slice__contents
                {
                    @include mi-gradient-radial(bottom 0 left 0, #a77eF6 0, #4811fe 15%);
                }
            }

            &--7 {
                @include mi-transform(rotate(2.5 * $es-rotation) skewY($es-skew));

                .ecosystem_pie__slice__contents
                {
                    @include mi-gradient-radial(bottom 0 left 0, #ff66f5 0, fade(#6b1870,80%) 18%);
                }
            }

            &--8 {
                @include mi-transform(rotate(3.5 * $es-rotation) skewY($es-skew));

                .ecosystem_pie__slice__contents
                {
                    background: transparent;

                    @include mi-no-shadow();
                }
            }
        }

        .ecosystem_pie__slice__contents
        {
            position: absolute;
            left: $indent-base;
            bottom: $indent-base;
            display: block;
            width: 200%;
            height: 200%;
            overflow: hidden;
            transform-style: preserve-3d;
            -webkit-transform-style: preserve-3d;

            @include mi-transition();
        }

        .ecosystem_pie__text
        {
            position: absolute;
            display: block;
            max-width: 28%;
            margin: 0 auto;
            z-index: 10;
            pointer-events: none;
            text-align: center;

            @include mi-transform-origin(center, center);
            @include mi-transition();

            &--1 {
                left: 14%;
                bottom: 25%;
            }

            &--2 {
                left: 6%;
                bottom: 45%;
            }

            &--3 {
                left: 12%;
                top: 23%;
            }

            &--4 {
                width: 30%;
                left: 0;
                right: 0;
                top: 13%;
            }

            &--5 {
                right: 16%;
                top: 23%;
            }

            &--6 {
                right: 9%;
                bottom: 48%;
            }

            &--7 {
                right: 8%;
                bottom: 25%;
            }

            &--8 {
                width: 20%;
                left: 0;
                right: 0;
                bottom: 13%;

                p
                {
                    font-size: $font-size-default;
                    color: $color-text;
                    text-shadow: none;
                }
            }
        }
    }

    @media (max-width: $screen-lg) {
        .ecosystem_pie__text--1
        {
            left: 11%;
            bottom: 22%;
        }

        .ecosystem_pie__text--2
        {
            left: 3%;
        }

        .ecosystem_pie__text--3
        {
            left: 9%;
            top: 23%;
        }

        .ecosystem_pie__text--4
        {
            top: 13%;
        }

        .ecosystem_pie__text--5
        {
            right: 16%;
            top: 24%;
        }

        .ecosystem_pie__text--6
        {
            right: 3%;
            bottom: 48%;
        }

        .ecosystem_pie__text--7
        {
            right: 7%;
            bottom: 21%;
        }

        .ecosystem_pie__text--8
        {
            bottom: 12%;
        }
    }

    @media (max-width: $screen-md)
    {
        .ecosystem_pie__text
        {
            display: block;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            margin: 0 0 $indent-xs;
            padding: $indent-hor $indent-m;
            text-align: center;
            border-radius: $border-radius-xl;

            @include mi-drop-shadow(0,2px,4px,0,0.5);

            h3
            {
                margin: 0;
                font-size: $font-size-default;
                text-align: center;

                img
                {
                    display: none;
                }
            }

            p, img
            {
                display: none;
            }

            &--1 {
                @include mi-gradient-horizontal(#e48108 30%, #f54325);
            }

            &--2 {
                @include mi-gradient-horizontal(#f7c22a 30%, #ff9501);
            }

            &--3 {
                @include mi-gradient-horizontal(#22c9b0 30%, #59be1a);
            }

            &--4 {
                @include mi-gradient-horizontal(#20b8f0, #1231dd);
            }

            &--5 {
                @include mi-gradient-horizontal(#469bb9, #161dbd);
            }

            &--6 {
                @include mi-gradient-horizontal(#a77eF6, #5500ff);
            }

            &--7 {
                @include mi-gradient-horizontal(#ff66f5, #680079);
            }

            &--8 {
                display: none;
            }
        }

        .ecosystem_pie__center
        {
            position: relative;
            margin: $indent-hor auto $indent-xxl;
            background: transparent;
        }

        &:before
        {
            content: "";
            position: absolute;
            width: 3px;
            display: block;
            left: 0;
            right: 0;
            top: $es-dim-core/2;
            bottom: $indent-m;
            margin: auto;
            background: #bbb;

            @include mi-drop-shadow(0,0,0,0.5);
        }
    }

    @media (max-width: $screen-xs)
    {
        .ecosystem_pie__center
        {
            width: $es-dim-core-mobile;
            height: $es-dim-core-mobile;
            margin: $indent-s auto $indent-xl;

            &:before
            {
                top: -$indent-s;
                right: -$indent-s;
                bottom: -$indent-s;
                left: -$indent-s;
            }
        }

        .ecosystem_pie__center__link
        {
            width: calc(100% - 2 * #{$indent-s});
            height: calc(100% - 2 * #{$indent-s});
            margin: $indent-s;

            img
            {
                width: auto;
                height: 4rem;
            }
        }
    }
}

