
.actions {
    padding: 0.2rem;
    text-align: left;

    &.min-height {
        min-height: 3rem;
    }

    a {
        font-style: italic;
        font-weight: $font-weight-semibold;

        &:hover,
        &:active {
            cursor: pointer;
            border-color: transparent;
            text-decoration: none;

            @include mi-no-shadow;
        }
    }

    .actions__title {
        font-family: var(--font-header);;
        font-size: $font-size-l;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
    }

    .actions__action--single,
    .actions__action--between {
        @extend .actions__action;
    }

    .actions__action--between {
        margin-right: $indent-base !important;
    }

    .actions__action--edit,
    .actions__action--delete,
    .actions__action--cancel,
    .actions__action--save,
    .actions__action--upload,
    .actions__action--view,
    .actions__action--graph,
    .actions__action--download,
    .actions_-action--share {
        @extend .actions__action;
    }

    .actions__action {
        position: relative;
        display: inline-block;
        line-height: $line-height-m;
        margin: 0 $indent-action;
        padding: 0;
        font-size: $font-size-default;
        font-style: italic;
        font-weight: $font-weight-semibold;
        color: $color-text-lighter;
        text-decoration: none;
        background: transparent;
        border: none;
        border-bottom: 1px solid transparent;

        @include mi-no-appearance;

        &:hover,
        &:active {
            cursor: pointer;
            text-decoration: none;
            border-color: inherit;

            @include mi-no-shadow;
        }

        &:active:focus {
            @include mi-no-shadow;
        }

        &:focus {
            @include mi-no-shadow;
        }

        &:before {
            vertical-align: top;
        }

        &.is-loading {
            padding-left: $indent-hor !important;
            margin-left: 0;
            border-color: transparent !important;

            @include mi-opacity(0.6);

            &:after {
                content: "\e900";
                left: 0;
                top: -0.5rem;
            }
        }

        &.center_align {
            left: 50%;

            @include mi-rotate-translate(0, -50%, 0);
        }

        &.margin-top {
            margin-top: $indent-m;
        }

        .action--no-padding {
            padding: 0;
        }

        &.green,
        &.actions__action--save {
            border-color: var(--color-cta-text);
            color: var(--color-cta-text) !important;

            &:hover,
            &:active {
                border-color: transparent;
                color: var(--color-cta-text) !important;
            }
        }
    }

    .actions__action--edit,
    .actions__action--delete,
    .actions__action--cancel,
    .actions__action--save,
    .actions__action--graph,
    .actions__action--upload,
    .actions__action--view,
    .actions__action--download,
    .actions__action--share {
        @extend .actions__action;

        &:before {
            @extend .icons;

            position: relative;
            top: -1px;
            margin-right: $indent-xs !important;
            line-height: unset;
        }
    }

    .actions__action--edit + .actions__action--edit {
        margin-right: $indent-m;
    }

    .actions__action--edit {
        &:before {
            content: "\e05f"
        }
    }

    .actions__action--delete {
        &:before {
            content: "\e054"
        }
    }

    .actions__action--upload {
        &:before {
            content: "\e084";
        }
    }

    .actions__action--graph {
        &:before {
            content: "\E077";
        }
    }

    .actions__action--view {
        &:before {
            content: "\e087";
        }
    }

    .actions__action--download {
        &:before {
            content: "\E083";
        }
    }

    .actions__action--share {
        &:before {
            content: "\E05A";
        }
    }

    .actions__action--cancel {
        border-bottom: 1px solid $color-border;

        &:hover,
        &:active {
            border-bottom-color: transparent;
        }
    }

    @media (max-width: $screen-xs) {
        width: 100%;

        .actions__action--edit:not(.stay-same),
        .actions__action--delete,
        .actions__action--view,
        .actions__action--upload,
        .actions__action--download,
        .actions__action--graph,
        .actions__action--share {
            display: inline-block;
            width: 5rem;
            height: 5rem;
            margin: 2px 0;
            border-radius: $border-radius;
            color: transparent;
            text-indent: -9999px;
            border: none;

            @include mi-transition();

            &:before {
                display: block;
                position: absolute;
                top: 0 !important;
                bottom: 0 !important;
                right: 0 !important;
                left: 0 !important;
                height: 2.4rem;
                width: 2.4rem;
                margin: auto !important;
                color: $color-grey-80;
                text-align: center;
                font-size: 2.4rem;
                text-indent: 0;
            }

            &:hover {
                cursor: pointer;
            }

            &.is-loading {
                padding-left: 0;

                @include mi-opacity(1);

                &:before {
                    display: none;
                }

                &:after {
                    @include mi-icomoon-font;

                    content: "\e900";
                    color: var(--color-cta-text);
                }
            }
        }

        .actions__action--delete {

            @include mi-drop-shadow(0, 1px, 2px, 0, 0.2);

            &:before {
                @include mi-icons-font;

                content: "\e054";
            }

            &:hover,
            &:active {
                background: $color-grey-5;

                @include mi-drop-shadow(0, 1px, 2px, 0, 0.4);
            }
        }

        .actions__action--edit:not(.stay-same) {
            background: $color-grey-5;

            @include mi-drop-shadow(0, 1px, 2px, 0, 0.2);

            &:before {
                @include mi-icons-font;

                content: "\e05f";
            }

            &:hover,
            &:active {
                @include mi-drop-shadow(0, 1px, 2px, 0, 0.4);
            }
        }

        .actions__action--upload,
        .actions__action--view,
        .actions__action--download,
        .actions__action--graph,
        .actions__action--share {
            background: $color-grey-5;

            @include mi-drop-shadow(0, 1px, 2px, 0, 0.2);

            &:hover,
            &:active {
                @include mi-drop-shadow(0, 1px, 2px, 0, 0.4);
            }
        }

        .actions__action--between {
            margin-right: 0 !important;
        }
    }
}
