.SingleDatePicker {
    width: 100%;


    .SingleDatePickerInput,
    .DateInput,
    .DateInput_input {
        width: 100%;
        background: transparent;
    }

    select {
        border: 0;
        border-bottom: 1px solid #484848;
        font-family: var(--font-default);
        font-size: $font-size-default;
        font-weight: $font-weight-regular;
        color: $color-grey;

        &.month {
            width: calc(60% - 59px);
            margin-right: 12px;
        }

        &.year {
            width: calc(40% - 47px)
        }
    }

    .DateInput_input,
    .DateInput_input__focused {
        width: 100%;
        border-bottom: 1px solid black;
        border-bottom-color: inherit;
        padding-left: 0;
        padding-right: 16px;
        display: block;
        font-family: var(--font-default);
        font-size: $font-size-default;
        font-weight: $font-weight-regular;
        color: inherit;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 1.6rem;
            width: 0;
            height: 0;
            border-bottom: none;
            border-left: 0.6rem solid transparent;
            border-right: 0.6rem solid transparent;
            border-top: 0.6rem solid #fff;
            border-top-color: #000;
        }
    }

    .SingleDatePicker_picker {
        z-index: 2;
    }
}
