

.select--alt-black {
  @extend .select--alt;

  .select__control {
    border-bottom-color: $color-text;
    color: $color-text;

    &.select__control--is-focused,
    &:hover,
    &:active {
      border-bottom-color: $color-text;

      @include mi-no-shadow;
    }
  }

  .select__placeholder {
    color: $color-text;
  }

  .select__input,
  .select__input input {
    color: $color-text;
  }

  .select__single-value {
    color: $color-text;
  }

  .select__indicators {
    .select__indicator {
      &:before {
        border-top-color: $color-text;
      }
    }
  }

  .select__menu {
    background-color: $color-default;
    z-index: 10;
    .select__menu-list {
      max-height: 20rem;
    }
    .select__option {
      color: $color-text;
    }
  }
}
