

.modal--form {
    @extend .modal;

    overflow: auto;

    h3 {
        text-align: left;
        line-height: $line-height-default;
        margin: $indent-xl 0 0;
    }

    .modal__container {
        h2 {
            margin: 0 0 $indent-hor;
        }

        &.no__padding {
            padding: 0;
        }

        .modal__intro {
            width: 80%;
            margin: 0 auto $indent-l auto !important;
        }

        .actions {
            position: relative;
            float: none;
            color: $color-grey-80;
            right: auto;
            bottom: auto;
            text-align: left;

            .actions__action--save,
            .actions__action--cancel {
                display: inline-block;
                margin: 0;
                font-size: $font-size-default;
                line-height: $line-height-default;
            }

            .actions__action--save {
                float: right;
                color: var(--color-cta-text);
            }
        }

        .form__fieldset {
            margin: 0 $indent-l $indent-xl;
            .form__cta-text {
                color: $color-theme;
            }
        }
    }

    @media (max-width: $screen-sm) {
        .modal__container {
            .form__fieldset {
                margin: 0 $indent-l;
            }
        }
    }

    @media (max-width: $screen-xs) {
        .modal__container {
            padding: $indent-l $indent-m $indent-m;

            .form__fieldset {
                margin: 0 0 $indent-m;
            }
        }
    }
}
