

.modal--scroll {
    @extend .modal;

    overflow: hidden;

    .modal__container {
        //padding: 0 2rem;

        .modal__content {
            //padding: 5rem 3.5rem 5rem;
            max-height: calc(65vh - 110px);
            color: $color-text;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        .button,
        .button--cta,
        .button--alt {
            position: absolute;
            right: 0;
            left: 0;
            bottom: $indent-l;
            width: 26rem;
            margin: 0 auto;
            z-index: 2;
            &.btn--cta--edited {
                position: relative !important;
                bottom: auto !important;
                margin: 1rem auto !important;
            }
        }

        .button--alt.button--link,
        .button--link {
            position: absolute;
            right: 3.5rem;
            left: auto;
            bottom: $indent-l;
            width: auto;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            z-index: 2;
        }

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
        }

        &:before {
            top: 0;
            height: 2.5rem;

            @include mi-gradient($color-bg 60%, rgba(255, 255, 255, 0));
        }

        &:after {
            bottom: 0;
            height: 2.5rem;

            @include mi-gradient(rgba(255, 255, 255, 0), $color-bg 40%);
        }
    }

    @media (max-width: $screen-xs) {
        .modal__container {
            padding-top: 0;

            .modal__content {
                padding: 4.2rem $indent-s 6.5rem;
                max-height: 75vh;
            }

            &:before {
                height: 5rem;

                @include mi-gradient($color-bg 50%, rgba(255, 255, 255, 0));
            }

            &:after {
                height: 12rem;

                @include mi-gradient(rgba(255, 255, 255, 0), $color-bg 40%);
            }

            .button,
            .button--cta,
            .button--alt,
            .button--alt.button--link,
            .button--link {
                bottom: $indent-s;
            }
        }
    }
}
