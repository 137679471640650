
.button--expand {
    display: block;
    margin: 0 auto;
    padding: 0;
    border: none;
    text-align: center;
    background: transparent;
    font-size: $font-size-default;
    font-weight: $font-weight-semibold;
    font-style: italic;
    border-bottom: 1px solid transparent;

    @include mi-transition();

    i {
        margin-left: $indent-l;
        font-weight: $font-weight-bold;
    }

    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
        border-bottom-color: $color-text;
    }

    @media (max-width: $screen-xs) {
        i {
            margin-left: $indent-xs;
        }
    }
}
