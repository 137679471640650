


.panel--diplomas {

    .panel

    * {
        background-color: red;
    }

    .select__menu {
        background-color: $color-default;
        position: relative;
        z-index: 1;
    }

    .select--alt {
        margin: 0 0 $indent-m;

        .select__control,
        .select__control.select__control--is-focused,
        .select__control:hover,
        .select__control:active {
            color: $color-text;
            border-bottom: 1px solid $color-border;
        }

        .select__input,
        .select__input input,
        .select__single-value,
        .select__placeholder {
            color: $color-text;
        }

        .select__indicators {
            .select__indicator {
                &:before {
                    border-top-color: $color-text;
                }
            }
        }
    }

    @media (max-width: $screen-xs) {
        .panel__body {
            .panel__diplomas {
                display: block;

                .Select,
                .Select:first-child,
                .Select:nth-child(2) {
                    display: block;
                    margin: 0 0 $indent-m;
                }
            }
        }
    }
}
