
.form__item {
  .tag_container{
    text-align: left;
  }

  .tag {
    margin: 8px 8px 0 0;
  }
}
