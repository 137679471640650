
.graph__container {
    table {
        thead {
            tr {
                th {
                    &.table_cell--index {
                        width: 2rem;
                    }
                }
            }
        }
    }
    .evaluation-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: $indent-s 0;
        div {
            margin: $indent-xxs;
        }
    }
    .legend-help-text {
        font-size: 11px;
    }
}

.actions__title--icon {
    font-size: 2rem;
}
