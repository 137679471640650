



.event {
  .time {
    color: $color-text-lighter;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.82px;
    line-height: 22px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    margin: 16px 0;
  }

  .description {
    //color: rgba(0,0,0,0.9);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
  }

  .button-box {
    @include mi-flexbox;
    @include mi-flex-direction(row);
    @include mi-flex-wrap(nowrap);

    margin-top: 8px;

    .flex-spacer {
      @include mi-flex(1 1 0);
    }

    //I could not use straight button--cta because then the button--cta
    // class would not be used fully by the outlined version
    .button--cta--adjusted {
      @extend .button--cta;

      @include mi-flex(none);
      display: inline-block;
      width: auto;
      font-style: italic;
      margin: 0 0 $indent-xs;
    }

    .button--cancel--outlined {
      @extend .button--cancel;

      @include mi-flex(none);
      display: inline-block;
      width: auto;
      color: $color-events-red;
      border: 1px solid;
      background-color: $color-default;
      font-style: italic;
      font-weight: 300;
      margin: 0 0 0 24px;

      &:hover,
      &:active,
      &:focus {
        color: $color-default;
        background-color: $color-events-red;
        border-color: $color-events-red;
        font-weight: 400;
      }
    }

    .button--cta--outlined {
      @extend .button--cta;
      @include mi-flex(none);
      display: inline-block;
      width: auto;
      color: $color-cta;
      border: 1px solid $color-cta;
      background-color: $color-default;
      font-style: italic;
      font-weight: 300;
      margin: 0 0 0 24px;

      &:hover,
      &:active,
      &:focus  {
        color: $color-default;
        background-color: $color-cta;
        font-weight: 500;
        border: 1px solid $color-cta;
      }
    }
  }

  .modal--form {
    .h2 {
      .green {
        color: $color-cta;
      }

      .red {
        color: $color-events-red
      }
    }

    .modal__subtitle {
      margin-top: -16px;
      margin-bottom: 24px;
      text-align: justify;
    }

    .modal__title {
      font-weight: 700;
    }

    .modal__time {
      margin-top: 8px;
      margin-bottom: 26px;
    }



    .modal__description {
      text-align: justify;
    }

    .actions {
      margin-top: 32px;

      .actions__action--save--red {
        float: right;
        border-color: $color-events-red;
        color: $color-events-red !important;

        &:hover,
        &:active {
          border-color: transparent;
          color: $color-events-red !important;
        }
      }
    }
  }
}
