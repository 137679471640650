
.page {
    margin: $indent-m 0 $indent-xl;
    padding: $indent-xl $indent-xxl;
    color: $color-text;
    background: $color-default;

    h1,
    h2,
    h3 {
        color: var(--color-theme);
    }

    @media (max-width: $screen-xs) {
        padding: $indent-s $indent-m;
    }

    .modal__step {
        margin-bottom: 0;
        color: $color-grey-40;
        font-size: $font-size-default;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
    }
}
