
.suggestion {
    position: relative;
    margin: 0;
    padding-bottom: 1.5rem;
    font-size: $font-size-default;
    line-height: $line-height-m;
    font-weight: $font-weight-bold;
    min-height: 5rem;
    @include mi-flexbox();
    @include mi-align-items(center);


    .suggestion__icon {
        position: relative;
        height: 4rem;
        margin-right: 3rem;
        font-size: 4rem;
        color: var(--color-theme);
    }

    .button--cta {
        margin-top: $indent-l;
    }

}
