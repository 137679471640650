

.select--solo {
    @extend .select;

    margin: 0 0 $indent-s;

    select {
        padding: $indent-xs $indent-xxl $indent-xs $indent-xl;
    }

    .select__icon_down {
        right: $indent-l;
    }
}
