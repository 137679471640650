
.Select.Select--single,
.Select.Select--single.is-open,
.Select.Select--single.is-focused,
.Select.Select--single.is-pseudo-focused,
.Select.Select--single.has-value,
.Select.Select--single.has-value.is-pseudo-focused {
    text-align: left;
    border-bottom: 1px solid $color-default;

    > .Select-control {
        width: 100%;
        padding: $indent-xs $indent-xl $indent-xs 0;
        background: transparent;
        border: none;
        border-radius: 0;
        border-collapse: collapse;
        font-size: $font-size-default;
        color: $color-default;

        @include mi-no-appearance;
        @include mi-no-shadow;
        @include mi-transition();

        .Select-value {
            padding: 0;
            background: transparent;

            .Select-value-label {
                background: transparent;
                color: $color-default !important;
            }
        }

        .Select-placeholder {
            padding: 0;
            color: $color-default;
            font-size: $font-size-default;
            font-style: italic;
        }
    }

    .Select-input {
        padding: 0;

        > input {
            height: $form-input-height;
            padding: 0;
            color: $color-default;
        }
    }

    .Select-arrow-zone {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        pointer-events: none;

        @include mi-transition();

        .Select-arrow {
            content: "";
            position: absolute;
            right: 0;
            top: 1.5rem;
            width: 0;
            height: 0;
            border-bottom: none;
            border-left: 0.6rem solid transparent;
            border-right: 0.6rem solid transparent;
            border-top: 0.6rem solid $color-default;
        }
    }

    .Select-menu-outer {
        border: none;
        border-radius: 0;
        overflow: hidden;

        @include mi-drop-shadow(0, 1px, 3px, 0, 0.2);

        .Select-option {
            padding: $indent-xs $indent-s;
            background: $color-default;
            color: $color-text;
            font-style: italic;

            @include mi-transition();

            &:last-child {
                border-bottom-right-radius: $border-radius-l;
                border-bottom-left-radius: $border-radius-l;
            }

            &.is-focused,
            &:hover {
                background: $color-grey-10;
            }

            &.is-selected {
                background: var(--color-theme-light);
                color: $color-default;
            }

            &.is-disabled {
                color: $color-grey-20;
                cursor: default;
            }
        }
    }
}
