
.data_block {
    position: relative;
    padding: $indent-s 0;
    &:first-child {
        padding-top: 0;
    }
    font-weight: $font-weight-medium;
    border-bottom: 1px solid $color-border-light;
    line-height: $line-height-m;
    
    &:last-child {
        border-bottom: none;
    }

    h3 {
        font-weight: $font-weight-bold;
        margin-bottom: 1rem;
    }

    h4 {
        margin: 0;
    }
}

.data_block__container {
    .data_block {
        border-bottom: 1px solid $color-border-light;
    }

    &:last-child {
        .data_block {
            border-bottom: none;
        }
    }
}
