
.button--back
{
    position: relative;
    display: block;
    margin: $indent-m 0;
    text-decoration: none;
    padding-left: $indent-l;

    i
    {
        position: absolute;
        left: 0;
        top: 0.2rem;

        @include mi-transition(0.2s);
    }

    &:hover,
    &:active
    {
        text-decoration: none !important;

        i
        {
            left: -$indent-action;
        }
    }
}
