
.filelister {
    table {
        width: 100%;
        border-collapse: collapse;



        .icon-doc {
            margin-right: $indent-xs;
        }

        .actions {
            text-align: right;

            .actions__action--edit,
            .actions__action--delete {
                line-height: $line-height-s;
                font-size: $font-size-s;
            }

            .actions__action--edit {
                margin-left: $indent-hor;
            }
        }

        thead {
            tr {
                th {
                    padding: $indent-s $indent-xs;
                    font-size: $font-size-s;
                    line-height: $line-height-s;
                    text-align: center;
                }
            }
        }

        tr {
            td {
                padding: 0.6rem $indent-xs;
                line-height: $line-height-m;
            }

            th,
            td {
                text-align: center;

                &:first-child {
                    text-align: left;
                    padding-left: $indent-l;
                }

                &:last-child {
                    text-align: right;
                    padding-right: $indent-l;
                    width: 1rem;
                }
            }
        }

        tbody {
            tr {
                padding: $indent-xs;

                &:nth-child(odd) {
                    background: $color-grey-10;
                }
            }
        }
    }
}
