
.list_item {
    list-style: none;
    position: relative;
    width: 100%;
    min-height: 9.5rem;
    padding: $indent-s 0;
    border-top: 1px solid $color-border-light;
    align-items: center;

    @include mi-flexbox();

    &:first-child {
        padding-top: $indent-xs;
        min-height: 9.5rem - $indent-s;
        border: none;

        .list_item__banner {
            top: $indent-xs;
        }
    }

    &:last-child {
        padding-bottom: 0;
    }

    h2,
    p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h3 {
        margin: $indent-xxs 0 $indent-xs;
        color: $color-grey-60;
        text-transform: none;
    }

    p {
        line-height: $line-height-m;
        margin: 0.5rem 0;
        font-size: $font-size-s;
        font-weight: $font-weight-medium;

        span {
            margin-top: 0;
            font-size: $font-size-default;
            font-style: italic;
        }
    }

    .list_item__content {
        @include mi-flex(1 1 0);
        max-width: 90%;

        @media (max-width: $screen-md) {
            max-width: 100%;
        }
        @media (max-width: $screen-sm) {
            max-width: 90%;
        }

        .actions {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;

            @include mi-align-self(flex-start);
        }

        .diploma_verified_icon {
            font-style: italic;
            .icons {
                font-size: $font-size-icon-xs;
                margin-right: $indent-xxs;
                vertical-align: text-top;
            }
        }
    }

    .actions {
        text-align: right;
        white-space: nowrap;
        overflow: hidden;

        @include mi-align-self(flex-end);

        .actions__action--edit,
        .actions__action--delete,
        .actions__action--download {
            margin: 0 $indent-action;
            padding: 0.8rem 0;
            line-height: $font-size-s;
            font-size: $font-size-s;
        }

        .actions__action--download {
            margin-right: $indent-l;
        }

        .actions__action--delete {
            margin-left: $indent-l;
        }
    }

    @media (max-width: $screen-md) {
        .actions {
            white-space: normal;

            .actions__action--download {
                margin-right: $indent-s;
            }

            .actions__action--delete {
                margin-left: $indent-s;
            }
        }
    }

    @media (max-width: $screen-sm) {
        display: block;

        .actions {
            position: relative;
            right: auto;
            top: auto;
            left: auto;
            display: block;
            width: 100%;
            margin-top: $indent-s;
            padding-left: 0;
            padding-right: 0;
            text-align: left;

            .actions__action--delete {
                float: right;
            }
        }

        &.include_download {

            .actions {

                .actions__action--delete {
                    float: right;
                }

                .actions__action--edit {
                    float: none;
                }
            }
        }
    }

    @media (max-width: $screen-xs) {
        padding: $indent-s 0 $indent-xs;

        .actions {
            position: static;
            margin: 0;
            text-align: left;

            .actions__action--edit,
            .actions__action--delete {
                float: right;
                display: inline-block;
                margin: 0;
                clear: none;
            }

            .actions__action--edit {
                float: left;
            }
        }

        p {
            br {
                display: block;
            }
        }

        &.include_download {
            .actions .actions__action--download {
                margin-right: $indent-xxs;
            }
        }
    }

    &:first-child {
        @media (max-width: $screen-sm) {
            .list_item__banner {
                top: $indent-xl;
            }
        }

        @media (max-width: $screen-xs) {
            .list_item__banner {
                top: $indent-xl;
            }
        }
    }
}

.list_item__banner {
    position: absolute;
    top: 0;
    right: 0;
}
