
$tag-check-width: 4.3rem;

.tag {
    position: relative;
    display: inline-block;
    margin: 0 $indent-m $indent-m 0;
    padding: 0;
    background: $color-grey-10;
    border-radius: $border-radius;

    @include mi-transition(0.5s);

    &.removing {
        @include mi-opacity(0.5);
    }

    .tag__text {
        padding: 0.9rem $indent-l 0.8rem $indent-s;

        &.tag__text__no_delete {
            padding: 0.9rem $indent-s;
        }
    }

    .tag__remove {
        position: absolute;
        top: 1.2rem;
        right: 0.8rem;
        display: block;
        width: 1.5rem;
        height: 1.5rem;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 1px;
            height: 1.5rem;
            margin: auto;
            background: $color-grey-80;

            @include mi-transition();
        }

        &:before {
            @include mi-rotate(45deg);
        }

        &:after {
            @include mi-rotate(-45deg);
        }

        &:hover,
        &:active {
            cursor: pointer;

            &:before,
            &:after {
                background: $color-alert;
            }
        }
    }

    .tag__check {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: $tag-check-width;
        padding: 1.1rem 1.7rem 0 1.7rem;
        border-right: 1px solid $color-border-light;

        + .tag__text {
            margin-left: $tag-check-width;
        }

        &:before {
            content: "";
            display: block;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            height: 1.4rem;
            width: 0.8rem;
            margin: auto;
            border-bottom: 1px solid $color-success;
            border-right: 1px solid $color-success;

            @include mi-rotate(40deg);
            @include mi-transition();
        }
    }
}
