
.evaluator-block {
    padding: 0 0 $indent-m;

  + .evaluator-block {
    border-top: 1px solid $color-border;
    margin-top: $indent-l;
    padding-top: $indent-l;
  }

    + .button--cta {
        margin: 0;
    }
}

.add-another {
  margin-top: $indent-xl;
}

.table__diploma-list {
  margin-bottom: $indent-xxl;

  &:last-child {
    border-bottom-color: black;
  }
}

.first-child {
  text-align: left !important;
}
