
$dropdown-dim: 3.8rem;

.dropdown {
    float: right;
    position: relative;
    display: inline-block;

    &.dropdown--active {
        .dropdown__content {
            display: block;
            z-index: 999;
            border-radius: $border-radius-xxl;
            overflow: hidden;
        }
    }
}

.dropdown__trigger {
    display: inline-block;
    width: auto;
    height: $dropdown-dim;
    line-height: $dropdown-dim;
    vertical-align: middle;

    .dropdown__image {
        display: inline-block;
        width: $dropdown-dim;
        height: $dropdown-dim;
        border-radius: 50%;
        vertical-align: top;
        overflow: hidden;
        background: $color-bg-darker;

        img {
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-height: 100%;
            max-width: 100%;

            @include mi-image-crisp();
        }
    }

    .dropdown__icon_down {
        float: right;
        margin-left: $indent-xs;
        line-height: $dropdown-dim;
    }
}

.dropdown__content {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 1.2rem;
    min-width: 20rem;
    background: $color-default;
    z-index: 99;

    @include mi-drop-shadow(0, 1px, 3px, 0, 0.3);

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
        z-index: 10;
    }

    &:before {
        right: 3.6rem;
        border-left: 0.8rem solid transparent;
        border-right: 0.8rem solid transparent;
        border-bottom: 0.8rem solid $color-grey-10;
    }

    &:after {
        right: 3.7rem;
        border-left: 0.7rem solid transparent;
        border-right: 0.7rem solid transparent;
        border-bottom: 0.7rem solid $color-default;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            cursor: pointer;
            list-style: none;

            a {
                position: relative;
                display: block;
                padding: 1.3rem $indent-m 1.3rem $indent-xl;
                color: $color-grey;
                font-weight: $font-weight-medium;
                font-style: italic;
                text-decoration: none;

                .icons {
                    position: absolute;
                    left: $indent-m;
                    top: $indent-base;
                    font-size: $font-size-default;
                }

                &:hover,
                &:active {
                    background: $color-grey-5;
                }
            }
        }

        hr {
            margin: 0;
            border: none;
            border-top: 1px solid $color-border-light;
        }
    }
}
