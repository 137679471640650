
$bullet-dim: 1.6rem;

.bullet_list {
    display: block;
    padding: 0;
    text-align: center;

    li {
        list-style: none;
        display: inline-block;
        margin: 0 $indent-xs / 2;
        vertical-align: top;

        a,
        span {
            display: block;
            width: $bullet-dim;
            height: $bullet-dim;
            border-radius: 50%;
            border: 1px solid $color-default;

            &.is-active {
                background: rgba(255, 255, 255, 0.4);
            }
        }
    }
}
