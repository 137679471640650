
.quote {
    text-align: center;
    font-weight: $font-weight-bold;
    font-style: italic;
    color: var(--color-theme);
    text-transform: none;
    .quote__quotation_mark {
        position: relative;
        top: 0.8rem;
        display: inline-block;
        margin: 0 0.5rem;
        font-size: $font-size-h2;
        line-height: $font-size-default;
        color: $color-grey-60;
    }
}
