
.select--alt {
    .select__control {
        position: relative;
        min-height: 3.4rem;
        padding: 0 $indent-m 0 0;
        line-height: 3.4rem;
        border: none;
        border-bottom: 1px solid $color-default;
        border-radius: 0;
        background: transparent;
        color: $color-default;

        &.select__control--is-focused,
        &:hover,
        &:active {
            border-bottom-color: $color-default;

            @include mi-no-shadow;
        }
    }

    .select__value-container {
        display: block;
        width: 100%;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mi-flex(none);

        > div {
            margin: 0;
            padding: 0;
        }
    }

    .select__placeholder {
        margin: 0;
        color: $color-default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        + div {
            margin: 0;
            padding: 0;
            line-height: $line-height-m;
        }
    }

    .select__input,
    .select__input input {
        display: block;
        height: $line-height-m;
        width: 100% !important;
        text-align: left;
        color: $color-default;
    }

    .select__single-value {
        margin: 0;
        color: $color-default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        + div {
            margin: 0;
            padding: 0;
        }
    }

    .select__indicator-separator {
        display: none;
    }

    .select__indicators {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        @include mi-flex(none);

        .select__indicator {
            display: block;
            height: 100%;

            &.select__loading-indicator {
                display: none !important; //Override inline styling
            }

            svg {
                display: none;
            }

            &:before {
                content: "";
                position: absolute;
                right: 0;
                top: 1.6rem;
                width: 0;
                height: 0;
                border-bottom: none;
                border-left: 0.6rem solid transparent;
                border-right: 0.6rem solid transparent;
                border-top: 0.6rem solid $color-default;
            }
        }
    }

    .select__menu {
        top: 100%;
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        overflow: hidden;
        z-index: 9999;

        @include mi-drop-shadow(0, 1px, 3px, 0, 0.2);

        .select__menu-list {
            margin: 0;
            padding: 0;
        }

        .select__option {
            padding: $indent-xs $indent-s;
            color: $color-text;
            font-style: italic;
            text-align: left;

            @include mi-transition();

            &:hover {
                cursor: pointer;
                &.select__option--is-focused {
                    background: $color-grey-10;
                }
            }

            &.select__option--is-focused {
                background: $color-grey-10;
            }

            &.select__option--is-selected {
                background: var(--color-theme-light);
                color: $color-default;
            }

            &.is-disabled {
                color: $color-grey-20;
                cursor: default;
            }
        }
    }
}
