$card-height-mobile: 38rem;
$card-stack-dim: 1.2rem;

.card__intro {
    max-width: 22rem;
    margin: $indent-m auto $indent-l auto;
    color: var(--color-theme);
    text-align: center;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;
}

.card__top {
    background-color: transparent;
    position: absolute;
    top: -4rem;
    left: 0;
    right: 0;

    .card__top__content {
        position: relative;
        display: inline-block;
        width: auto;
        line-height: $line-height-m;
        margin: $indent-m auto;
        z-index: 997;
        padding: 1rem $indent-base 0.6rem;
        background: #e5e7ea; // a color that matches $color-grey-10 but is not transparent.
        color: $color-grey-80;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-default;
        border-radius: $indent-m;

        @include mi-drop-shadow-color();
    }
}

.card {
    position: relative;
    width: 100%;
    height: $card-height;
    margin: 0 0 $indent-xl;
    padding: 0;
    background: $color-bg;
    text-align: center;
    border-radius: $border-radius-l;
    overflow: visible;

    @extend .clearfix;
    @include mi-drop-shadow(0, 1px, 3px, 0, 0.2);

    &:after,
    &:before {
        content: "";
        height: 1rem;
        position: absolute;
        bottom: -$card-stack-dim;
        left: $card-stack-dim;
        right: $card-stack-dim;
        background: $color-default;
        border-bottom-left-radius: $border-radius-l;
        border-bottom-right-radius: $border-radius-l;

        @include mi-drop-shadow(0, 1px, 3px, 0, 0.2);
    }

    &:after {
        left: $card-stack-dim * 2;
        right: $card-stack-dim * 2;
        bottom: -$card-stack-dim * 2;
    }

    .card__inner {
        height: 100%;
    }

    .card__content {
        position: relative;
        display: block;
        height: 46rem;
        padding: $indent-base $indent-xl;
        overflow: hidden;
        border-radius: $border-radius $border-radius 0 0;

        &:before {
            content: "";
            display: inline-block;
            height: 100%;
            width: 0.001%;
            vertical-align: middle;
        }

        .card__content__inner {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
        }
    }

    .card__subject {
        margin: $indent-m 0;
        line-height: 1.2;
        font-family: var(--font-header);;
        font-size: 4rem;
        color: var(--color-theme);
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        max-width: 100%;
        hyphens: auto;

        &.small {
            font-size: 5rem;
        }

        &.xsmall {
            font-size: 3.5rem;
        }
    }

    .card__text {
        margin: 0;
        color: $color-grey-60;
        font-weight: $font-weight-bold;
    }

    .card__image {
        margin: $indent-m 0;
        max-width: 80%;
        height: auto;
        max-height: 20rem;
    }

    .card__footer {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 12rem;
        padding: $indent-hor $indent-base;
        background: $color-bg;
        border-bottom-left-radius: $border-radius-l;
        border-bottom-right-radius: $border-radius-l;

        .button--small,
        .button--icon {
            display: inline-block;
            margin: 0 $indent-s;
            vertical-align: middle;
        }
    }

    @media (max-width: $screen-md) {
        .card__subject {
            font-size: 3.2rem;
        }
    }

    @media (max-width: $screen-xs) {
        height: $card-height-mobile;
        margin: 0 0 $indent-xl;

        .card__subject {
            font-size: 2.4rem;
        }

        .card__content {
            height: 30rem;
            padding: $indent-base $indent-base 0;
        }

        .card__subject,
        .card__image {
            margin: $indent-s 0;
        }

        .card__footer {
            height: 8rem;
            padding: $indent-m 0;
        }
    }

    @media (max-width: $screen-xxs) {
        .card__subject {
            font-size: 3rem;
            word-wrap: break-word;
        }
    }
}
