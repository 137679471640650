

.card--back {
    width: 100%;
    height: $card-height;
    margin: 0 0 $indent-xl;
    background: $color-bg;
    text-align: center;
    border-radius: $border-radius-l;

    @extend .clearfix;
    @include mi-drop-shadow(0, 1px, 3px, 0, 0.3);

    &:after,
    &:before {
        content: "";
        height: 1rem;
        position: absolute;
        background: $color-default;
        border-bottom-left-radius: $border-radius-l;
        border-bottom-right-radius: $border-radius-l;

        @include mi-drop-shadow(0, 1px, 3px, 0, 0.2);
    }

    &:before {
        bottom: -$card-stack-dim;
        left: $card-stack-dim;
        right: $card-stack-dim;
    }

    &:after {
        left: $card-stack-dim * 2;
        right: $card-stack-dim * 2;
        bottom: -$card-stack-dim * 2;
    }

    h2 {
        margin-top: 0;
        color: var(--color-theme);
    }

    .card__inner {
        height: $card-height;
        padding: 3rem 0 0;
        overflow: hidden;
    }

    .card__content {
        max-height: $card-height - 3rem;
        padding: 5rem 14rem 11rem;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .card__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 8.3rem;
        z-index: 2;
    }

    .card__close {
        margin: 0 $indent-base $indent-m;
    }

    ul {
        display: inline-block;
        margin: 0 0 $indent-m;
        padding: 0;
        text-align: left;

        li {
            list-style: none;
            position: relative;
            padding-left: 3.5rem;
            color: $color-grey-80;
            font-weight: $font-weight-medium;

            .icons {
                position: absolute;
                left: 0;
                top: 2px;
                width: 2rem;
                text-align: center;
                font-size: $font-size-default;
                color: var(--color-theme);
            }
        }
    }

    .card__fade {
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
        }

        &:before {
            top: 0;
            height: 9rem;
            border-top-left-radius: $border-radius-l;
            border-top-right-radius: $border-radius-l;

            @include mi-gradient($color-bg 60%, rgba(255, 255, 255, 0));
        }

        &:after {
            bottom: 0;
            height: 16rem;
            border-bottom-left-radius: $border-radius-l;
            border-bottom-right-radius: $border-radius-l;

            @include mi-gradient(rgba(255, 255, 255, 0), $color-bg 40%);
        }
    }

    @media (max-width: $screen-md) {
        .card__content {
            padding: 5rem $indent-m 11rem;
        }
    }

    @media (max-width: $screen-xs) {
        height: $card-height-mobile;

        .card__inner {
            height: $card-height-mobile;
            padding-top: $indent-xs;
        }

        .card__content {
            height: $card-height-mobile;
            padding: 7rem $indent-m 11rem;
        }

        .card__footer {
            height: 6.5rem;
        }

        .card__close {
            margin: 0 $indent-hor $indent-base;
        }
    }
}
