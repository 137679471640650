

.button--cancel {
    @extend .button;

    background: $color-grey-10;
    color: $color-text;

    &:hover,
    &:active,
    &:focus {
        color: $color-text;
        background: $color-grey-20
    }
}
